import React from 'react';
import styles from './EditarCategorias.module.css';
import Header from '../../Header/Header';
import Input from '../../Forms/Input';
import useForm from '../../Hooks/useForm';
import Button from '../../Forms/Button';
import PickTax from '../AdicionarExercicio/PickTaxSingle';
import { TERMS_DELETE, TERMS_POST } from '../../../api';
import useFetch from '../../Hooks/useFetch';
import Error from '../../Helper/Error';
import { AppContext } from '../../Context/AppContext';

const EditarCategorias = () => {
  const { data, request, loading, error } = useFetch();
  const token = window.localStorage.getItem('token');
  const [validate, setValidate] = React.useState(true);
  const { setNotify } = React.useContext(AppContext);
  const catEspecialidades = useForm();
  const catObjetivos = useForm();
  const catDificuldades = useForm();
  const catEsforco = useForm();
  const catEquipamentos = useForm();
  const catGrupamento = useForm();
  const [especialidades, setEspecialidades] = React.useState([]);
  const [objetivos, setObjetivos] = React.useState([]);
  const [dificuldades, setDificuldades] = React.useState([]);
  const [esforco, setEsforco] = React.useState([]);
  const [equipamentos, setEquipamentos] = React.useState([]);
  const [grupamento, setGrupamento] = React.useState([]);

  const [reload, setReload] = React.useState(true);

  async function addTerm(nameTerm, tax, set) {
    //console.log(nameTerm);

    if (eval(tax).value === '') return;

    setReload(false);

    const body = {
      taxonomy: nameTerm,
      new_term_name: eval(tax).value,
    };

    console.log(body);

    const { url, options } = TERMS_POST({ body, token });
    const { response } = await request(url, options);
    //  console.log(json);
    if (response.ok) {
      setNotify({
        type: 'success',
        message: 'Categoria alterada',
      });
    } else {
      setNotify({
        type: 'error',
        message: 'Houve um erro.',
      });
    }
    eval(tax).onClear();
    eval(set)([]);
    setReload(true);
  }

  async function deleteTerm(term, nameTerm) {
    //console.log(term);

    let termID = term.value;
    if (!termID) return;

    setReload(false);
    if (window.confirm('Confirma exclusão desta categoria?') == true) {
      const { url, options } = TERMS_DELETE({ termID, token });
      const { response } = await request(url, options);
      //  console.log(json);
      if (response.ok) {
        setNotify({
          type: 'success',
          message: 'Categoria deletada',
        });
      } else {
        setNotify({
          type: 'error',
          message: 'Houve um erro.',
        });
      }
    }
    eval(nameTerm)([]);
    setReload(true);
  }

  return (
    <section className={styles.editCategorias}>
      <Header page="editCategorias" />
      <div className={styles.leftBar}>
        <h1 className={styles.title}>Editar Categorias</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.selectTerms}>
          <div className={styles.boxTerms}>
            <Input
              label="Modalidades"
              type="text"
              name="especialidades"
              {...catEspecialidades}
            />

            <Button
              onClick={() =>
                addTerm(
                  'especialidades',
                  'catEspecialidades',
                  'setEspecialidades',
                )
              }
            >
              Adicionar
            </Button>
            {reload && (
              <PickTax
                slug={'especialidades'}
                nome={'Modalidades'}
                value={especialidades}
                setValue={setEspecialidades}
              />
            )}

            <Button
              onClick={() => deleteTerm(especialidades, 'setEspecialidades')}
            >
              Remover
            </Button>
          </div>

          <div className={styles.boxTerms}>
            <Input
              label="Objetivos"
              type="text"
              name="objetivos"
              {...catObjetivos}
            />

            <Button
              onClick={() =>
                addTerm('objetivos', 'catObjetivos', 'setObjetivos')
              }
            >
              Adicionar
            </Button>
            {reload && (
              <PickTax
                slug={'objetivos'}
                nome={'Objetivos'}
                value={objetivos}
                setValue={setObjetivos}
              />
            )}

            <Button onClick={() => deleteTerm(objetivos, 'setObjetivos')}>
              Remover
            </Button>
          </div>
          <div className={styles.boxTerms}>
            <Input
              label="Dificuldades"
              type="text"
              name="dificuldades"
              {...catDificuldades}
            />

            <Button
              onClick={() =>
                addTerm('dificuldades', 'catDificuldades', 'setDificuldades')
              }
            >
              Adicionar
            </Button>
            {reload && (
              <PickTax
                slug={'dificuldades'}
                nome={'Dificuldades'}
                value={dificuldades}
                setValue={setDificuldades}
              />
            )}

            <Button onClick={() => deleteTerm(dificuldades, 'setDificuldades')}>
              Remover
            </Button>
          </div>
          <div className={styles.boxTerms}>
            <Input label="Esforco" type="text" name="esforco" {...catEsforco} />

            <Button
              onClick={() => addTerm('esforco', 'catEsforco', 'setEsforco')}
            >
              Adicionar
            </Button>
            {reload && (
              <PickTax
                slug={'esforco'}
                nome={'Esforço'}
                value={esforco}
                setValue={setEsforco}
              />
            )}

            <Button onClick={() => deleteTerm(esforco, 'setEsforco')}>
              Remover
            </Button>
          </div>

          <div className={styles.boxTerms}>
            <Input
              label="Equipamentos"
              type="text"
              name="equipamentos"
              {...catEquipamentos}
            />

            <Button
              onClick={() =>
                addTerm('equipamentos', 'catEquipamentos', 'setEquipamentos')
              }
            >
              Adicionar
            </Button>
            {reload && (
              <PickTax
                slug={'equipamentos'}
                nome={'Equipamentos'}
                value={equipamentos}
                setValue={setEquipamentos}
              />
            )}

            <Button onClick={() => deleteTerm(equipamentos, 'setEquipamentos')}>
              Remover
            </Button>
          </div>

          <div className={styles.boxTerms}>
            <Input
              label="Grupamento"
              type="text"
              name="grupamento"
              {...catGrupamento}
            />

            <Button
              onClick={() =>
                addTerm('grupamento', 'catGrupamento', 'setGrupamento')
              }
            >
              Adicionar
            </Button>
            {reload && (
              <PickTax
                slug={'grupamento'}
                nome={'Grupamento Muscular'}
                value={grupamento}
                setValue={setGrupamento}
              />
            )}

            <Button onClick={() => deleteTerm(grupamento, 'setGrupamento')}>
              Remover
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditarCategorias;
