import React from 'react';
import Footer from '../../Footer';
import Header from '../../Header/Header';
import styles from './Aluno.module.css';
import { UserContext } from '../../Context/UserContext';
import Error from '../../Helper/Error';
import { Link, Routes, Route, useLocation } from 'react-router-dom';
import EditarCadastroAluno from './EditarCadastroAluno';
import EditarTreinoAluno from './EditarTreinoAluno';
import Stats from './Stats';
import useFetch from '../../Hooks/useFetch';
import { USER_GET_BY_ID } from '../../../api';
import Loading from '../../Helper/Loading';

const Aluno = () => {
  const token = window.localStorage.getItem('token');
  const { userID } = React.useContext(UserContext);
  const path = window.location.pathname;
  const [theID, setTheID] = React.useState(
    Number(path.replace(/\/$/, '').split('/').pop()),
  );
  const { data, loading, error, request } = useFetch();

  const location = useLocation();

  function getAge(dateString) {
    var dia = dateString.split('-')[0];
    var mes = dateString.split('-')[1];
    var ano = dateString.split('-')[2];

    const total =
      ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2);

    var ageInMilliseconds = new Date() - new Date(total);
    return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365); // convert to years
  }

  //---FETCH DOS DADOS
  React.useEffect(() => {
    async function fetchDados() {
      const { url, options } = USER_GET_BY_ID({ token, theID });
      const { response, json } = await request(url, options);
      //console.log(json);
    }
    fetchDados();
  }, [location]);

  return (
    <section className={styles.aluno}>
      <Header page="manage_aluno" />
      <div className={styles.leftBar}>
        <div className={styles.userPhotoArea}>
          {data && (
            <div
              className={styles.image}
              style={{ backgroundImage: ` url('${data.photo}')` }}
            ></div>
          )}
          <div className={styles.dados}>
            <div className={styles.pontos}>{data && data.pontuacao} </div>
            <p className={styles.nome}>
              {data && data.nome} {data && data.sobrenome}
            </p>
            {data && (
              <p className={styles.idade}>
                Idade: {getAge(data.nascimento)} anos
              </p>
            )}
            {data && (
              <p className={styles.desde}>
                Desde:{' '}
                {data && data.desde.slice(0, 10).split('-').reverse().join('/')}
              </p>
            )}
          </div>
        </div>
        <ul className={styles.alunoMenu}>
          <li className={`${styles.icon} ${styles.editarTreino}`}>
            <Link to={`editar-treino-aluno/${theID}`}>EDITAR TREINO</Link>
          </li>
          <li className={`${styles.icon} ${styles.editarCadastro}`}>
            <Link to={`editar-cadastro-aluno/${theID}`}>EDITAR CADASTRO</Link>
          </li>
          <li className={`${styles.icon} ${styles.stats}`}>
            <Link to={`estatisticas/${theID}`}>ESTATÍSTICAS</Link>
          </li>
        </ul>
      </div>
      <div className={styles.content}>
        <Routes>
          <Route
            path="editar-treino-aluno/*"
            element={<EditarTreinoAluno alunoData={data} alunoID={theID} />}
          />
          <Route
            path="editar-cadastro-aluno/*"
            element={<EditarCadastroAluno alunoData={data} alunoID={theID} />}
          />
          <Route
            path="estatisticas/*"
            element={<Stats alunoData={data} alunoID={theID} />}
          />
        </Routes>
      </div>
      <Footer />
    </section>
  );
};

export default Aluno;
