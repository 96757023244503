import React from 'react';
import MainMenu from './MainMenu';
import styles from './LogoArea.module.css';

const LogoArea = () => {
  return (
    <div className={styles.logoArea}>
      <MainMenu />
      <div className={styles.logo}></div>
    </div>
  );
};

export default LogoArea;
