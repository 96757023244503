import React from 'react';
import styles from './SelectEditGroup.module.css';
import Button from './../Forms/Button';
import { ModalContext } from '../Context/ModalContext';

const SelectEditGrup = ({ atributos, index }) => {
  const [series, setSeries] = React.useState(atributos[0].value);
  const [repeticoes, setRepeticoes] = React.useState(atributos[1].value);
  const [fazerpor, setFazerpor] = React.useState(atributos[2].value);
  const [manterpor, setManterpor] = React.useState(atributos[3].value);
  const [descanso, setDescanso] = React.useState(atributos[4].value);
  const [carga, setCarga] = React.useState(atributos[5].value);
  const [elastico, setElastico] = React.useState(atributos[6].value);

  const { itensExercicio, setItensExercicio, dataModal, setDataModal, nItem } =
    React.useContext(ModalContext);

  //console.log(dataModal[index][1].atributos[0].value);
  console.log(dataModal);
  const myID = dataModal[index][0].dados.id;
  //console.log(myID);

  const updateArr = [
    { label: 'Séries', value: series, id: 1 },
    { label: 'Repetições', value: repeticoes, id: 2 },
    { label: 'Fazer Por', value: fazerpor, id: 3 },
    { label: 'Manter Por', value: manterpor, id: 4 },
    { label: 'Descanso', value: descanso, id: 5 },
    { label: 'Carga', value: carga, id: 6 },
    { label: 'Elástico', value: elastico, id: 7 },
  ];

  function handleValueChange() {
    const editItens = dataModal[index];
    // console.log(editItens);

    const tempArr = [
      [
        {
          dados: {
            ...editItens[0].dados,
          },
        },
        {
          atributos: [...updateArr],
        },
      ],
    ];
    //  console.log(tempArr);

    setDataModal((prevState) => {
      //console.log(prevState);
      // console.log(tempArr[0]);
      return prevState.map((item) =>
        item[0].dados.id !== myID ? item : tempArr[0],
      );
    });

    //console.log(itensExercicio[0][0].dados.grade_exercicios);
    console.log(nItem);
  }

  React.useEffect(() => {
    const auxGrade = [...itensExercicio];
    auxGrade[nItem][0].dados.grade_exercicios = JSON.stringify(dataModal);
    setItensExercicio(() => auxGrade);
    // console.log(JSON.parse(itensExercicio[nItem][0].dados.grade_exercicios));
    // console.log(dataModal);
  }, [dataModal]);

  return (
    <div className={styles.combos}>
      <span>
        <label htmlFor="series" className={styles.label}>
          Séries
        </label>
        <input
          type="number"
          id="series"
          name="series"
          min="1"
          max="300"
          value={series}
          onChange={(e) => {
            setSeries(e.target.value);
          }}
        />
      </span>

      <span>
        <label htmlFor="repeticoes">Repetições</label>
        <input
          type="text"
          id="repeticoes"
          name="repeticoes"
          min="1"
          max="300"
          value={repeticoes}
          onChange={(e) => {
            //Testa se são somente números e sinal de +
            if (/^[0-9 ()+]+$/.test(e.target.value)) {
              if (!e.target.value.includes('++')) {
                //console.log('ok');
                setRepeticoes(e.target.value);
                //setFinal(eval(value));
              }
            }
          }}
        />
      </span>

      <span>
        <label htmlFor="fazerpor">Fazer por</label>
        <select
          id="fazerpor"
          name="fazerpor"
          defaultValue={fazerpor}
          onChange={(e) => {
            setFazerpor(e.target.value);
          }}
        >
          <option value="0"></option>
          <option value="5s">5s</option>
          <option value="10s">10s</option>
          <option value="15s">15s</option>
          <option value="20s">20s</option>
          <option value="25s">25s</option>
          <option value="30s">30s</option>
          <option value="35s">35s</option>
          <option value="40s">40s</option>
          <option value="45s">45s</option>
          <option value="50s">50s</option>
          <option value="55s">55s</option>
          <option value="60s">60s</option>
          <option value="2min">2min</option>
          <option value="3min">3min</option>
          <option value="4min">4min</option>
          <option value="5min">5min</option>
          <option value="6min">6min</option>
          <option value="7min">7min</option>
          <option value="8min">8min</option>
          <option value="9min">9min</option>
          <option value="10min">10min</option>
          <option value="15min">15min</option>
          <option value="20min">20min</option>
          <option value="25min">25min</option>
          <option value="30min">30min</option>
          <option value="35min">35min</option>
          <option value="40min">40min</option>
          <option value="45min">45min</option>
          <option value="50min">50min</option>
          <option value="60min">60min</option>
        </select>
      </span>

      <span>
        <label htmlFor="manterpor">Manter por</label>
        <select
          id="manterpor"
          name="manterpor"
          defaultValue={manterpor}
          onChange={(e) => {
            setManterpor(e.target.value);
          }}
        >
          <option value="0"></option>
          <option value="5s">5s</option>
          <option value="10s">10s</option>
          <option value="15s">15s</option>
          <option value="20s">20s</option>
          <option value="25s">25s</option>
          <option value="30s">30s</option>
          <option value="35s">35s</option>
          <option value="40s">40s</option>
          <option value="45s">45s</option>
          <option value="50s">50s</option>
          <option value="55s">55s</option>
          <option value="60s">60s</option>
          <option value="2min">2min</option>
          <option value="3min">3min</option>
          <option value="4min">4min</option>
          <option value="5min">5min</option>
          <option value="6min">6min</option>
          <option value="7min">7min</option>
          <option value="8min">8min</option>
          <option value="9min">9min</option>
          <option value="10min">10min</option>
          <option value="15min">15min</option>
          <option value="20min">20min</option>
          <option value="25min">25min</option>
          <option value="30min">30min</option>
        </select>
      </span>

      <span>
        <label htmlFor="descanso">Descanso</label>
        <select
          id="descanso"
          name="descanso"
          defaultValue={descanso}
          onChange={(e) => {
            setDescanso(e.target.value);
          }}
        >
          <option value="0"></option>
          <option value="5s">5s</option>
          <option value="10s">10s</option>
          <option value="15s">15s</option>
          <option value="20s">20s</option>
          <option value="25s">25s</option>
          <option value="30s">30s</option>
          <option value="35s">35s</option>
          <option value="40s">40s</option>
          <option value="45s">45s</option>
          <option value="50s">50s</option>
          <option value="55s">55s</option>
          <option value="60s">60s</option>
          <option value="2min">2min</option>
          <option value="3min">3min</option>
          <option value="4min">4min</option>
          <option value="5min">5min</option>
          <option value="6min">6min</option>
          <option value="7min">7min</option>
          <option value="8min">8min</option>
          <option value="9min">9min</option>
          <option value="10min">10min</option>
          <option value="15min">15min</option>
          <option value="20min">20min</option>
          <option value="25min">25min</option>
          <option value="30min">30min</option>
        </select>
      </span>

      <span>
        <label htmlFor="carga">Carga</label>
        <input
          type="text"
          id="carga"
          name="carga"
          min="1"
          max="100"
          value={carga}
          onChange={(e) => {
            setCarga(e.target.value);
          }}
        />
      </span>

      <span className={styles.large}>
        <label htmlFor="elastico">Elástico</label>
        <select
          id="elastico"
          name="elastico"
          defaultValue={elastico}
          onChange={(e) => {
            setElastico(e.target.value);
          }}
        >
          <option value="00"></option>
          <option value="Extra-Leve">Extra-Leve</option>
          <option value="Leve">Leve</option>
          <option value="Médio">Médio</option>
          <option value="Forte">Forte</option>
          <option value="Extra-forte">Extra-Forte</option>
        </select>
      </span>
      <Button onClick={handleValueChange}>SALVAR</Button>
    </div>
  );
};

export default SelectEditGrup;
