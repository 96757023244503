import React from 'react';
import { EXERCICIOS_GET } from '../../../api';
import useFetch from '../../Hooks/useFetch';
import styles from './SelectExercicios.module.css';
import AsyncSelect from 'react-select/async';

//style React Select
const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: '1px solid #eee',
    fontSize: '1rem',
    padding: '.6rem',
    borderRadius: '.4rem',
    margin: '15px 0',
    '&:hover': {
      border: '1px solid #eb6018',
    },
  }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
    };
  },
};

const SelectExercicios = ({ setSearchField }) => {
  const token = window.localStorage.getItem('token');
  //Número de resultados = todos
  const nItens = -1;
  const page = 1;
  const term = '';
  const rascunhos = '';
  const q = '';

  //pega o request e data do fetch
  const { data, request, error } = useFetch();

  const [filteredArray, setFilteredArray] = React.useState(null); //array filtrado somente id, label

  const loadOptions = (inputValue, callback) => {
    //só realiza o filtro se o array não for null
    if (filteredArray) {
      //pega o valor digitado tranforma para lowercase e filtra o array
      const filterExercicios = (inputValue) => {
        return filteredArray.filter((i) =>
          i.label?.toLowerCase().includes(inputValue?.toLowerCase()),
        );
      };

      //espera a digitação para devolver o valor digitado
      setTimeout(() => {
        callback(filterExercicios(inputValue));
      }, 1000);
    } else callback(null);
  };
  //console.log(data);
  React.useEffect(() => {
    async function fetchExercicios() {
      //Só realiza enquento o filtro for null
      if (!filteredArray) {
        const { url, options } = EXERCICIOS_GET({
          nItens,
          term,
          page,
          token,
          q,
          rascunhos,
        });
        const { response, json } = await request(url, options);
        //setApi(await json);
        //console.log(response);
        //console.log(json);

        //só realiza qdo a promessa estiver resolvida
        if (data) {
          //filtra array somente com campo id e label
          setFilteredArray(
            data.map((item) => ({
              id: item.id,
              label: item.nome,
              post_type: item.post_type,
            })),
          );
        }
      }
    }

    fetchExercicios();
    // console.log('oi');
  }, [filteredArray, nItens, request, data]);

  function pickExercicio(opt) {
    // console.log(opt);
    setSearchField(opt);
  }

  if (error) console.log(error);
  if (data)
    return (
      <div className={styles.rascunhoBar}>
        <div className={styles.search}>
          <AsyncSelect
            cacheOptions
            loadOptions={loadOptions ? loadOptions : () => null}
            onChange={pickExercicio}
            value=""
            placeholder={
              !filteredArray
                ? 'Aguarde, carreganto...'
                : 'Adicione um exercício'
            }
            defaultOptions
            styles={colourStyles}
          />
        </div>
      </div>
    );
};

export default SelectExercicios;
