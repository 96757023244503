import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Components/Pages/Home/Home';
import Login from './Components/Pages/Login/Login';
import Aluno from './Components/Pages/Aluno/Aluno';
import MeuTreino from './Components/Pages/MeuTreino/MeuTreino';
import Instrutor from './Components/Pages/Instrutor/Instrutor';
import AddExercicio from './Components/Pages/AdicionarExercicio/AddExercicio';
import EditarExercicio from './Components/Pages/EditarExercicio/EditarExercicio';
import EditarCategorias from './Components/Pages/EditarCategorias/EditarCategorias';
import AddUsuario from './Components/Pages/AdicionarUsuario/AddUsuario';
import EditarUsuario from './Components/Pages/EditarUsuario/EditarUsuario';
import ListarUsuarios from './Components/Pages/ListarUsuarios/ListarUsuarios';
import AddTreino from './Components/Pages/AdicionarTreino/AddTreino';
import EditarTreino from './Components/Pages/EditarTreino/EditarTreino';
import AddGrupo from './Components/Pages/AdicionarGrupo/AddGrupo';
import EditarGrupo from './Components/Pages/EditarGrupo/EditarGrupo';
import ListarGrupos from './Components/Pages/ListarGrupos/ListarGrupos';
import ListarTreinos from './Components/Pages/ListarTreinos/ListarTreinos';
import ListarExercicios from './Components/Pages/ListarExercicios/ListarExercicios';
import { UserStorage } from './Components/Context/UserContext';
import { AppStorage } from './Components/Context/AppContext';
import { ModalStorage } from './Components/Context/ModalContext';
import ProtectedInfo from './Components/Helper/ProtectedInfo';
import ManagerAdv from './Components/Pages/ManageAdv/ManageAdv';
import Ranking from './Components/Pages/Ranking/Ranking';
import './App.css';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <ModalStorage>
          <UserStorage>
            <AppStorage>
              <Routes>
                <Route path="/login/*" element={<Login />} />
                <Route path="/" element={<Home />} />
                <Route
                  path="/aluno/*"
                  element={
                    <ProtectedInfo type={['master', 'instrutor']}>
                      <Aluno />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/instrutor/*"
                  element={
                    <ProtectedInfo type={['master']}>
                      <Instrutor />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/listar-exercicios"
                  element={
                    <ProtectedInfo type={['master', 'instrutor']}>
                      <ListarExercicios />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/adicionar-exercicio"
                  element={
                    <ProtectedInfo type={['master']}>
                      <AddExercicio />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/editar-exercicio/*"
                  element={
                    <ProtectedInfo type={['master']}>
                      <EditarExercicio />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/editar-categorias"
                  element={
                    <ProtectedInfo type={['master']}>
                      <EditarCategorias />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/adicionar-usuario"
                  element={
                    <ProtectedInfo type={['master', 'instrutor']}>
                      <AddUsuario />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/editar-usuario"
                  element={
                    <ProtectedInfo
                      type={['master', 'instrutor', 'aluno1', 'aluno2']}
                    >
                      <EditarUsuario />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/listar-usuarios"
                  element={
                    <ProtectedInfo type={['master', 'instrutor']}>
                      <ListarUsuarios />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/adicionar-treino"
                  element={
                    <ProtectedInfo type={['master', 'instrutor']}>
                      <AddTreino />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/adicionar-grupo"
                  element={
                    <ProtectedInfo type={['master', 'instrutor']}>
                      <AddGrupo />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/editar-grupo/*"
                  element={
                    <ProtectedInfo type={['master', 'instrutor']}>
                      <EditarGrupo />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/listar-treinos"
                  element={
                    <ProtectedInfo type={['master', 'instrutor']}>
                      <ListarTreinos />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/listar-grupos"
                  element={
                    <ProtectedInfo type={['master', 'instrutor']}>
                      <ListarGrupos />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/editar-treino/*"
                  element={
                    <ProtectedInfo type={['master', 'instrutor']}>
                      <EditarTreino />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/meu-treino/*"
                  element={
                    <ProtectedInfo type={['aluno1', 'aluno2']}>
                      <MeuTreino />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/manager-area"
                  element={
                    <ProtectedInfo type={['master']}>
                      <ManagerAdv />
                    </ProtectedInfo>
                  }
                />
                <Route
                  path="/ranking"
                  element={
                    <ProtectedInfo type={['master']}>
                      <Ranking />
                    </ProtectedInfo>
                  }
                />
              </Routes>
            </AppStorage>
          </UserStorage>
        </ModalStorage>
      </BrowserRouter>
    </div>
  );
};

export default App;
