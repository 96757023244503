import React from 'react';
import styles from './NotifyMessage.module.css';
import { AppContext } from '../Context/AppContext';

const NotifyMessage = () => {
  const { notify, setNotify } = React.useContext(AppContext);
  const [active, setActive] = React.useState(false);

  const messageRef = React.createRef();

  React.useEffect(() => {
    if (notify.type !== '') {
      setActive(true);
      setTimeout(() => {
        setActive(false);
        setNotify({ type: '', message: '' });
      }, 2000);
    }
  }, [notify]);

  return (
    <div
      className={`${styles.notify} ${
        notify.type === 'error' ? styles.error : styles.success
      } ${active && styles.active}`}
      ref={messageRef}
    >
      <p className={styles.message}>{notify.message}</p>
      <span className={styles.progressBar}>
        <div className={styles.progress}></div>
      </span>
    </div>
  );
};

export default NotifyMessage;
