import React from 'react';
import styles from './FeedExercicios.module.css';
import VideoItem from './VideoItem';
import { EXERCICIOS_GET } from '../../../api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Helper/Loading';

const FeedExercicios = ({ page, term, nItens, setEndPages, endPages, q }) => {
  const token = window.localStorage.getItem('token');
  const [listaExercicios, setListaExercicios] = React.useState([]);

  const { data, loading, error, request } = useFetch();
  const rascunhos = '';
  // console.log(nItens);

  React.useEffect(() => {
    async function fetchExercicios() {
      const { url, options } = EXERCICIOS_GET({
        page,
        term,
        nItens,
        token,
        q,
        rascunhos,
      });
      const { response, json } = await request(url, options);
      //verifica se terminou os itens
      setListaExercicios(json);
      if (response && response.ok && json.length < nItens) setEndPages(true);
    }
    fetchExercicios();
  }, [request, page, term, setEndPages, nItens, endPages, q]);

  if (loading && !endPages) return <Loading type="default" />;
  if (data)
    return (
      <ul className={styles.lista}>
        {listaExercicios &&
          listaExercicios.map((item) => (
            <VideoItem
              key={item.id}
              item={item}
              setListaExercicios={setListaExercicios}
            />
          ))}
      </ul>
    );
  else return null;
};

export default FeedExercicios;
