import React from 'react';
import ResetForm from './ResetForm';
import styles from './ResetForm.module.css';
const LoginPasswordReset = () => {
  return (
    <section className={styles.login}>
      <div className={styles.form}>
        <ResetForm />
      </div>
    </section>
  );
};

export default LoginPasswordReset;
