import React from 'react';
import styles from './Weeks.module.css';
import {
  TREINOS_ALUNO_GET,
  TREINO_ALUNO_PUT,
  USER_CALENDARIO_PUT,
} from '../../../api';
import Loading from '../../Helper/Loading';
import { AppContext } from '../../Context/AppContext';
import useFetch from '../../Hooks/useFetch';
import Video from '../ListarExercicios/Video';
import StopWatch from './StopWatch';
import Button from '../../Forms/Button';

const Weeks = ({ treino, setCurrentDay, today, alunoData, setCalendario }) => {
  const token = window.localStorage.getItem('token');
  const ids = treino.treinos.map((item) => item.value);
  //console.log(ids);
  const [grade, setGrade] = React.useState([]);
  const { data, loading, error, request } = useFetch();

  const [modal, setModal] = React.useState(false);
  const [video, setVideo] = React.useState();
  const [filterVideo, setFilterVideo] = React.useState();
  const { setNotify } = React.useContext(AppContext);

  //cronometro

  const [startTime, setStartTime] = React.useState();
  const [running, setRunning] = React.useState(false);
  const [isStoped, setIsStoped] = React.useState(true);
  const [storage, setStorage] = React.useState([]);
  const [count, setCount] = React.useState(0);

  const [carga, setCarga] = React.useState(0);
  const [activeItem, setActiveItem] = React.useState();
  const [typeActiveItem, setTypeActiveItem] = React.useState();
  const [groupActiveItem, setGroupActiveItem] = React.useState();

  /*   const [isActive, setIsActive] = React.useState(false);
  const [isPaused, setIsPaused] = React.useState();
  const [time, setTime] = React.useState({
    sec: 0,
    min: 0,
    hr: 0,
  });
  */

  const [totalTime, setTotalTime] = React.useState();
  const [finishTime, setFinishTime] = React.useState();

  //pontos
  const [pontos, setPontos] = React.useState(0);

  /*   function isJson(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  let grade = isJson(dados.grade_exercicios); */

  React.useEffect(() => {
    //console.log(alunoData.pontuacao);
    //console.log(alunoData.calendario);
    async function fetchEx() {
      const { url, options } = TREINOS_ALUNO_GET({ token, ids });
      const { json } = await request(url, options);
      //console.log(json);

      if (json.length > 0) {
        let datinha = [];
        for (let i = 0; i < json.length; i++) {
          const getArr1 = json[i].grade_exercicios;
          const getArr2 = getArr1.replaceAll('"[[{"', '[[{"');
          const getArr3 = getArr2.replaceAll('}]}]]",', '}]}]],');
          datinha.push(JSON.parse(getArr3));
        }
        //console.log(datinha);
        // const tempArr = getArr3.map((item) => JSON.parse(item.grade_exercicios));
        //const tempArr = JSON.parse(getArr3);
        // const tempArr2 = [];
        // for (let i = 0; i < tempArr.length; i++) {
        //    tempArr2.push(...tempArr[i]);
        //  }
        //  console.log(tempArr2);
        setGrade(datinha);

        let points = [];
        datinha.map((item) => {
          item.map((i) => {
            /*-------------SE FOR EXERCÍCIO ÚNICO---------------*/
            //-- Aqui ele verifica se o número de repetições está preenchido, se não estiver pega o valor do FAZER POR e substitui o S por '' e o min por '00', depois divide por 2 --//

            if (i[0].dados.post_type === 'exercicios') {
              // console.log(i[1].atributos);

              //Verifica se repetições está vázio
              if (
                i[1].atributos[1].value !== '' &&
                Number(i[1].atributos[1].value) !== 0
              ) {
                points.push(
                  i[1].atributos[0].value * eval(i[1].atributos[1].value),
                );
                //Se repeticoes estivr vazio pega o campo FAZER POR
              } else {
                points.push(
                  (i[1].atributos[0].value *
                    eval(
                      i[1].atributos[2].value
                        .replaceAll('s', '0')
                        .replaceAll('min', '00'),
                    )) /
                    10,
                );
              }
              /*-------------SE ESTIVER EM UM GRUPO---------------*/
            } else {
              let rep = i[0].dados.repeticoes;
              i[0].dados.grade_exercicios.map((i2) => {
                //Verifica se repetições está vázio
                if (
                  i2[1].atributos[1].value !== '' &&
                  Number(i2[1].atributos[1].value) !== 0
                ) {
                  points.push(
                    i2[1].atributos[0].value *
                      eval(i2[1].atributos[1].value) *
                      rep,
                    //Se repeticoes estivr vazio pega o campo FAZER POR
                  );
                } else {
                  points.push(
                    ((i2[1].atributos[0].value *
                      eval(
                        i2[1].atributos[2].value
                          .replaceAll('s', '0')
                          .replaceAll('min', '00'),
                      )) /
                      10) *
                      rep,
                  );
                }
              });
            }
          });
        });

        const reducer = (accumulator, curr) => accumulator + curr;
        setPontos(points.reduce(reducer));
        //console.log(points.reduce(reducer));

        //setPontos(points);
      }
    }
    fetchEx();
  }, []);

  async function handleClick(item, num, group, type) {
    //console.log(ids);
    setModal(true);
    //console.log(grade[index][1].atributos);
    //console.log(treino.treinos[0].value);
    setVideo(item);
    setCarga(item[1].atributos[5].value);
    setActiveItem(num);
    setTypeActiveItem(type);
    setGroupActiveItem(group);
  }

  React.useEffect(() => {
    setTotalTime(
      (Object.values(storage).reduce((acc, value) => value + acc, 0) + count) *
        1000,
    );
    // console.log(totalTime);
  }, [count]);

  async function handleFinish() {
    // console.log(time);
    //console.log(pontos);
    // console.log(today);
    // console.log(treino.day);

    //Se quando clicar em concluir estiver em Stop ele pega o storage, se estiver com play ligado pega o storage e soma com count atual
    //console.log(Object.values(storage).reduce((acc, value) => value + acc, 0));

    let ff = 0;
    if (isStoped)
      ff = Object.values(storage).reduce((acc, value) => value + acc, 0) * 1000;

    if (!isStoped)
      ff =
        (Object.values(storage).reduce((acc, value) => value + acc, 0) +
          count) *
        1000;

    //5400000 = 1h30

    if (ff > 5400000) ff = 5400000;

    const newDate = {
      ...alunoData.calendario,
      [today]: { pontos: pontos, time: ff },
    };
    // console.log(body);

    setCalendario(newDate);

    const body = {
      calendario: newDate,
      pontuacao: Number(alunoData.pontuacao) + Number(pontos),
    };

    //console.log(grade[0]);

    const body2 = {
      grade_exercicios: grade[0],
    };

    //console.log(body);

    const alunoID = alunoData.id;
    const { url, options } = USER_CALENDARIO_PUT({ token, body, alunoID });
    const { response } = await request(url, options);
    if (response.ok) {
      setNotify({
        type: 'success',
        message: 'Treino do dia finalizado',
      });
      setCurrentDay('');

      //faz o fetch para editar as cargas dos treinos
      const exID = treino.treinos[0].value;
      const { url, options } = TREINO_ALUNO_PUT({ token, body2, exID });
      const { json, response } = await request(url, options);
      //console.log(json);
    } else {
      setNotify({
        type: 'error',
        message: error,
      });
    }
  }

  function handleCarga(event) {
    event.preventDefault();
    setCarga(event.target.value);
  }

  function submitCarga(e, video) {
    e.preventDefault();
    //console.log(video);
    //console.log(grade);
    //console.log(grade[0][activeItem]);

    // console.log(grade[0][activeItem][1].atributos[5].value);

    if (typeActiveItem === 1) {
      let obj2 = {
        ...grade,
        ...(grade[0][activeItem][1].atributos[5].value = carga),
      };
    } else {
      let obj2 = {
        ...grade,
        ...(grade[0][groupActiveItem][0].dados.grade_exercicios[
          activeItem
        ][1].atributos[5].value = carga),
      };
    }

    setModal(false);
    //setGrade(obj2);
  }

  if (loading) return <Loading type="default" />;
  if (data && data.length > 0)
    return (
      <div className={styles.weeks}>
        {modal && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <div className={styles.atributos}>
                <h1>{video[0].dados.nome}</h1>
                <h2>Instruções:</h2>
                <p>
                  {video[1].atributos[0].value !== '' ? (
                    <strong>{video[1].atributos[0].label}: </strong>
                  ) : (
                    ''
                  )}
                  {video[1].atributos[0].value}
                </p>
                <p>
                  {video[1].atributos[1].value !== '' ? (
                    <strong>{video[1].atributos[1].label}: </strong>
                  ) : (
                    ''
                  )}
                  {video[1].atributos[1].value}
                </p>
                <p>
                  {video[1].atributos[2].value !== '' ? (
                    <strong>{video[1].atributos[2].label}: </strong>
                  ) : (
                    ''
                  )}
                  {video[1].atributos[2].value}
                </p>
                <p>
                  {video[1].atributos[3].value !== '' ? (
                    <strong>{video[1].atributos[3].label}: </strong>
                  ) : (
                    ''
                  )}
                  {video[1].atributos[3].value}
                </p>
                <p>
                  {video[1].atributos[4].value !== '' ? (
                    <strong>{video[1].atributos[4].label}: </strong>
                  ) : (
                    ''
                  )}
                  {video[1].atributos[4].value}
                </p>
                <p className={styles.pcarga}>
                  {video[1].atributos[5].value !== '' ? (
                    <>
                      <strong>{video[1].atributos[5].label}: </strong>
                      <input
                        type="text"
                        name="carga"
                        value={carga}
                        onChange={handleCarga}
                        className={styles.inputs}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </p>
                <p>
                  {video[1].atributos[6].value !== '' ? (
                    <strong>{video[1].atributos[6].label}: </strong>
                  ) : (
                    ''
                  )}
                  {video[1].atributos[6].value}
                </p>
              </div>

              <Button
                onClick={(e) => submitCarga(e, video)}
                style={{ width: '150px' }}
              >
                SALVAR
              </Button>
            </div>
            <div className={styles.close} onClick={() => setModal(false)}></div>
          </div>
        )}
        <div className={styles.title}>
          <h1>{treino.dia}</h1>
          <span
            className={`${styles.icon} ${styles.back}`}
            onClick={() => setCurrentDay('')}
          ></span>
        </div>
        {today === treino.day ? (
          <StopWatch
            startTime={startTime}
            setStartTime={setStartTime}
            running={running}
            setRunning={setRunning}
            isStoped={isStoped}
            setIsStoped={setIsStoped}
            storage={storage}
            setStorage={setStorage}
            count={count}
            setCount={setCount}
          />
        ) : (
          <div className={styles.doneNull}>
            Ops, este treino só pode ser realizado no dia correto.
          </div>
        )}
        <div className={styles.videosContent}>
          {grade.map((item) =>
            item.map((i, j) => (
              <div key={i[0].dados.id}>
                {i[0].dados.post_type === 'exercicios' ? (
                  <div className={styles.videoItem}>
                    <div className={styles.videoPlayer}>
                      <Video
                        codigo={i[0].dados.codigo_video}
                        size="720p"
                        controls={true}
                      />
                    </div>
                    <div className={styles.videoDesc}>
                      <p>{i[0].dados.nome}</p>
                      <div
                        className={styles.icon + ' ' + styles.openAtributos}
                        onClick={() => handleClick(i, j, j, 1)}
                      ></div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.grupo}>
                    <h3>{i[0].dados.nome}</h3>
                    <p>Execute na sequência por {i[0].dados.repeticoes}x</p>
                    {i[0].dados.grade_exercicios.map((i2, x) => (
                      <div key={i2[0].dados.id} className={styles.videoItem}>
                        <div className={styles.videoPlayer}>
                          <Video
                            codigo={i2[0].dados.codigo_video}
                            size="720p"
                            controls={true}
                          />
                        </div>
                        <div className={styles.videoDesc}>
                          <p>{i2[0].dados.nome}</p>
                          <div
                            className={styles.icon + ' ' + styles.openAtributos}
                            onClick={() => handleClick(i2, x, j, 2)}
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )),
          )}
        </div>
        {/* 1800000 -> 30min*/}
        {totalTime > 300000 ||
        Object.values(storage).reduce((acc, value) => value + acc, 0) * 1000 >
          300000 ? (
          <div className={styles.done} onClick={handleFinish}>
            FINALIZAR
          </div>
        ) : (
          <div className={styles.doneNull}>
            O TREINO SÓ PODE SER FINALIZADO APÓS 30MIN.
          </div>
        )}
      </div>
    );
  else {
    return (
      <div className={styles.weeks}>
        <div className={styles.title}>
          <h1>{treino.dia}</h1>
          <span
            className={`${styles.icon} ${styles.back}`}
            onClick={() => setCurrentDay('')}
          ></span>
        </div>
        <div className={styles.doneNull}>
          Nenhum treino programado para o dia de hoje.
        </div>
      </div>
    );
  }
};

export default Weeks;
