import React from 'react';
import BoxFinanceiro from './BoxFinanceiro';
import styles from './Home.module.css';
import Header from '../../Header/Header';
import Footer from '../../Footer';
import { Navigate, useNavigate } from 'react-router-dom';
import Video from '../ListarExercicios/Video';
import {
  INSTRUTOR_CREF_GET,
  HOME_STATS_GET,
  USER_GET_BY_ID,
  USER_PUT,
} from '../../../api';
import useFetch from '../../Hooks/useFetch';
import BoxRanking from './BoxRanking';
import Loading from '../../Helper/Loading';
import Input from '../../Forms/Input';
import Button from '../../Forms/Button';
import useForm from '../../Hooks/useForm';
import { UserContext } from '../../Context/UserContext';
import { AppContext } from '../../Context/AppContext';
import Error from '../../Helper/Error';

const Home = () => {
  const { userID, userAuthor, userType, userForceActivate, userStatus, login } =
    React.useContext(UserContext);
  // console.log(userType);
  //const navigate = useNavigate();

  const { request, loading } = useFetch();
  const token = window.localStorage.getItem('token');

  const [authorData, setAuthorData] = React.useState();
  const [instrutorVideo, setInstrutorVideo] = React.useState();
  const [instrutorBanner, setInstrutorBanner] = React.useState();
  const [homeStats, setHomeStats] = React.useState();

  const [userData, setUserData] = React.useState();
  const [firstTime, setFirstTime] = React.useState(2);
  const { setNotify } = React.useContext(AppContext);
  const nome = useForm();
  const sobrenome = useForm();
  const email = useForm('email');
  const whatsapp = useForm('phone');
  const nascimento = useForm('date');
  const password = useForm();
  const [erro, setErro] = React.useState('');
  const [termos, setTermos] = React.useState(false);
  const [quest, setQuest] = React.useState(1);
  const [preview, setPreview] = React.useState(
    'https://api.nascidosparatreinar.com/wp-content/themes/api/images/placeholder-id.png',
  );
  const [file, setFile] = React.useState();
  const [loadingPic, setLoadingPic] = React.useState(false);

  const currentDate = new Date();
  const mes = String(currentDate.getMonth() + 1).padStart(2, '0');
  const ano = currentDate.getFullYear();

  const [userRanking, setUserRanking] = React.useState();

  //GET INSTRUTOR DATA
  React.useEffect(() => {
    const theID = userAuthor;
    async function fetchInstrutor() {
      const { url, options } = INSTRUTOR_CREF_GET({ token, theID });
      const { json } = await request(url, options);
      //console.log(json);
      setAuthorData(json);
      //console.log(userStatus);
      if (userType === 'instrutor') {
        setInstrutorVideo(json.video_instrutor);
        setInstrutorBanner(json.banner_instrutor);
      }
    }
    fetchInstrutor();
  }, [userAuthor]);

  //---GET USER DATA
  React.useEffect(() => {
    if (userID) {
      const theID = userID;
      async function fetchDados() {
        const { url, options } = USER_GET_BY_ID({ token, theID });
        const { response, json } = await request(url, options);
        //console.log(json.first_visit);
        setUserData(json);
        setFirstTime(json.first_visit);
      }
      fetchDados();
    }
  }, [userID]);

  function handleClick1(e) {
    e.preventDefault();
    setQuest(2);
  }

  //---BOTÃO DE ENVIO DE EMAIL
  async function handleClick2(e) {
    e.preventDefault();
    setErro('');

    const body = {
      ...userData,
      nome: nome.value,
      sobrenome: sobrenome.value,
      email: email.value,
      whatsapp: whatsapp.value,
      //force_activate: 1,
      //status: 1,
      first_visit: 0,
      password: password.value,
      nascimento: nascimento.value,
    };
    //console.log(body);

    if (validaFields()) {
      try {
        const alunoID = userID;
        const { url, options } = USER_PUT({ token, body, alunoID });
        const { response, json } = await request(url, options);
        //console.log(json);
        if (response.ok) {
          setNotify({
            type: 'success',
            message: 'Usuário alterado.',
          });
          setQuest(2);
        } else {
          setNotify({
            type: 'error',
            message: 'Usuário já existe.',
          });
        }
      } catch (err) {
        setErro(err.message);
      } finally {
        setErro('');
      }
    } else {
      setErro('Preencha os campos corretamente.');
    }
  }

  const validaFields = () => {
    setErro('');
    if (
      nome.validate() &&
      sobrenome.validate() &&
      email.validate() &&
      whatsapp.validate() &&
      nascimento.validate() &&
      password.validate()
    ) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (userData) {
      //  console.log(alunoData.first_visit);
      nome.initialValue(userData.nome);
      sobrenome.initialValue(userData.sobrenome);
      email.initialValue(userData.email);
      whatsapp.initialValue(userData.whatsapp);
      setFirstTime(userData.first_visit);
    }
  }, [userData]);

  const handleSubmit = () => {
    setLoadingPic(true);
    const formData = new FormData();

    formData.append('files', file);
    //console.log(formData);
    const photo = formData;

    async function fetchPic() {
      fetch('https://api.nascidosparatreinar.com/json/api/user_image', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          setNotify({
            type: 'success',
            message: 'Foto alterada.',
          });
          setLoadingPic(false);
          setFirstTime('0');
        })
        .catch((error) => {
          setNotify({
            type: 'error',
            message: 'Houve um Erro.',
          });
        });
    }
    fetchPic();
  };

  function handleChange(e) {
    setErro('');
    if (e.target.files[0]) {
      if (e.target.files[0].size > 5000000) {
        setErro('A imagem deve ter no máx 5mb');
      } else {
        setFile(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]));
      }
    }
  }

  /*--------------------*/

  //quantidade de dias no mes
  function getDaysInMonth(ano, mes) {
    return new Date(ano, mes, 0).getDate();
  }
  const totalDays = getDaysInMonth(ano, mes);

  /*-----------*/

  //Função pega a data inicial e final da semana e gera as datas intermediarias
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    const dates = [];
    while (date <= endDate) {
      dates.push(new Date(date).toLocaleDateString());
      date.setDate(date.getDate() + 1);
    }

    //troca / por -
    for (let i = 0; i < dates.length; i++) {
      dates[i] = dates[i].replace(/\//g, '-');
    }
    return dates;
  }

  //Gera a data inicial e final da semana e envia para funcao acima
  const curr = new Date();
  const first = curr.getDate() - curr.getDay(); // Start from Monday
  const firstDate = new Date(curr.setDate(first));
  const lastDate = new Date(curr.setDate(firstDate.getDate() + 6));
  const currentWeek = getDatesInRange(firstDate, lastDate);
  //console.log(currentWeek);

  //GET HOME STATS
  React.useEffect(() => {
    if (userType === 'master' || userType === 'instrutor') {
      async function fetchStats() {
        const { url, options } = HOME_STATS_GET({ token });
        const { response, json } = await request(url, options);
        // console.log(json);
        setHomeStats(json);

        /*---FAZ O LOOP EM CADA ELEMNTO, SELECIONA SOMENTE O MES ATUAL E SOMA OS PONTOS----*/
        let ranking = [];
        json[0].forEach((item, i) => {
          let currentDates = Object.keys(item.calendario).filter(
            (item) =>
              //item.includes(mes + '-' + ano), // se o rankig for por mês
              //pega as datas da semana atual e verifica se existe treino
              currentWeek.some((e) => e.match(item)), // se for por semana
          );

          //console.log(currentDates);

          //retorna array com datas do mes atual
          const filtered = Object.keys(item.calendario)
            .filter((key) => currentDates.includes(key))
            .reduce((obj, key) => {
              obj[key] = item.calendario[key];
              return obj;
            }, {});

          //cria array com datas completas do mes atual
          let allDates = [];
          for (let i = 0; i <= totalDays; i++) {
            allDates.push(String(i).padStart(2, '0') + '-' + mes + '-' + ano);
          }

          //completa os arrays vazios com valores
          let emptyArr = allDates.reduce(
            (a, v) => ({ ...a, [v]: { pontos: 0, time: 0 } }),
            {},
          );

          //Faz o merge do array dos treinos com os vazios
          let mixArr = { ...emptyArr, ...filtered };

          ranking.push({
            nome: item.nome,
            sobrenome: item.sobrenome,
            author: item.author,
            photo: item.photo,
            pontos: Object.values(mixArr)
              .map((item) => {
                return item.pontos;
              })
              .reduce((a, b) => {
                return a + b;
              }),
          });

          //console.log(Object.values(filtered)[0].pontos);
        });

        //filtra somente os 5 primeiros colocados e coloca em ordem crescente
        let arr = ranking.sort((a, b) => b.pontos - a.pontos);
        let arr2 = [];
        //se a qtd for maior que 5 ele limita a 5
        const count = ranking.length < 5 ? ranking.length : 5;
        for (let index = 0; index < count; index++) {
          arr2.push(ranking[index]);
        }

        setUserRanking(arr2);
        //console.log(ranking.sort((a, b) => a.pontos + b.pontos));
        // console.log(arr2);
      }
      fetchStats();
    }
  }, []);

  //Checa se a activação forçada estiver ligada
  if (userForceActivate !== '1' && userType !== 'master')
    if (userStatus === 'red' || userStatus === '' || userStatus === 0)
      return (
        <div className="forbidden">
          <h1>
            Por favor,
            <br />
            entre em contato com
            <br />
            seu instrutor.
          </h1>
        </div>
      );

  if (userType === 'master')
    return (
      <section className={styles.home}>
        <Header />
        <div className={styles.banner}></div>
        <div className={styles.boxes}>
          <BoxFinanceiro
            titulo="Alunos Cadastrados"
            valor={homeStats && homeStats[2].alunos}
            icon="users"
          />
          <BoxFinanceiro
            titulo="Instrutores Cadastrados"
            valor={homeStats && homeStats[3].instrutores}
            icon="instrutor"
          />
          <BoxFinanceiro
            titulo="Exercícios Cadastrados"
            valor={homeStats && homeStats[1].exercicios}
            icon="exercicios"
          />
        </div>
        <div className={styles.boxes3}>
          <h1 className={styles.subTitle}>Ranking da Semana</h1>
          {loading && <Loading type="dots" />}
          {userRanking &&
            userRanking.map((item, i) => (
              <BoxRanking
                key={i}
                foto={item.photo}
                nome={item.nome + ' ' + item.sobrenome}
                pontos={item.pontos}
                instrutor={item.author}
                colocacao={i}
                userType={userType}
              />
            ))}
        </div>
        <Footer />
      </section>
    );

  /*--------------------------------------------------------------------------*/
  if (userType === 'instrutor')
    if (userData && firstTime === '1')
      return (
        <section className={styles.meuTreino}>
          <Header page="firstTime" />
          <div className={styles.leftBar}>
            <h1 className={styles.title}>Finalize seu Cadastro</h1>
          </div>
          {quest === 1 && (
            <div className={styles.content}>
              <div className={styles.basics}>
                <Input label="Nome" type="text" name="nome" {...nome} />
                <Input
                  label="Sobrenome"
                  type="email"
                  name="sobrenome"
                  {...sobrenome}
                />
                <Input label="Email" type="email" nome="email" {...email} />
                <Input
                  label="Whatsapp"
                  type="email"
                  nome="email"
                  {...whatsapp}
                />
                <Input
                  label="Data de nascimento"
                  type="text"
                  nome="text"
                  placeholder="DD-MM-AAAA"
                  {...nascimento}
                />
                <Input
                  label="Nova Senha"
                  type="password"
                  name="senha"
                  {...password}
                />
              </div>
              <div className={styles.checks}>
                <input
                  type="checkbox"
                  value={termos}
                  onChange={() => setTermos(!termos)}
                />
                Li e concordo com os termos contratados{' '}
                <a
                  href="https://api.nascidosparatreinar.com/wp-content/uploads/2022/10/contrato_NPT.pdf"
                  target="_blank"
                >
                  (Ver Termos)
                </a>
              </div>

              <div className={styles.btEnviar}>
                {loading || !termos ? (
                  <Button disabled>Salvar</Button>
                ) : (
                  <Button onClick={handleClick2}>Salvar</Button>
                )}
                {!validaFields.value && <Error message={erro} />}
              </div>
            </div>
          )}
          {quest === 2 && (
            <div className={styles.content}>
              <div className={styles.basics2}>
                <div className={styles.picContent}>
                  {preview && (
                    <div
                      className={styles.preview}
                      style={{ backgroundImage: `url('${preview}'` }}
                    ></div>
                  )}
                  <input
                    type="file"
                    id="file"
                    className={styles.inputFile}
                    name="file"
                    onChange={handleChange}
                  />
                  <label htmlFor="file">
                    <span>Escolha a foto</span>
                  </label>
                  {loadingPic ? (
                    <Button disabled>Salvando...</Button>
                  ) : (
                    <Button onClick={handleSubmit}>CONCLUIR CADASTRO</Button>
                  )}
                  <Error message={erro} />
                </div>
              </div>
            </div>
          )}
        </section>
      );

  /*--------------------------*/

  if (userData && firstTime === '0')
    return (
      <section className={styles.home}>
        <Header />
        <div className={styles.banner_video}>
          {authorData && instrutorVideo !== '' && (
            <Video
              codigo={authorData.video_instrutor}
              size="240p"
              controls={true}
            />
          )}
          {authorData && instrutorVideo === '' && (
            <img src={instrutorBanner} alt="Banner" />
          )}
        </div>

        <div className={styles.boxes2}>
          <BoxFinanceiro
            titulo="Cliente desde:"
            valor={
              userData &&
              userData.desde.substring(10, 0).split('-').reverse().join('-')
            }
            icon="users"
          />
          <BoxFinanceiro
            titulo="Exercícios Cadastrados"
            valor={homeStats && homeStats[1].exercicios}
            icon="exercicios"
          />
        </div>
        <div className={styles.boxes4}>
          <h1 className={styles.subTitle}>Ranking da Semana</h1>
          {loading && <Loading type="dots" />}
          {userRanking &&
            userRanking.map((item, i) => (
              <BoxRanking
                key={i}
                foto={item.photo}
                nome={item.nome + ' ' + item.sobrenome}
                pontos={item.pontos}
                instrutor={item.author}
                colocacao={i}
                userType={userType}
              />
            ))}
        </div>

        <Footer />
      </section>
    );

  /*----------------------------------------------------------------------------*/
  if (userType === 'aluno1' || userType === 'aluno2')
    //window.location.href = '/meu-treino';
    return <Navigate to="/meu-treino" />;

  /*----------------------------------------------------------------------------*/
  if (!login)
    //window.location.href = '/meu-treino';
    return <Navigate to="/login" />;
};

export default Home;
