import React from 'react';
import styles from './EditarExercicio.module.css';
import Header from '../../Header/Header';
import Input from '../../Forms/Input';
import useForm from '../../Hooks/useForm';
import Button from '../../Forms/Button';
import PickTax from '../../Pages/AdicionarExercicio/PickTax';
import { EXERCICIO_EDIT_GET, EXERCICIO_PUT } from '../../../api';
import useFetch from '../../Hooks/useFetch';
import Error from '../../Helper/Error';
import { AppContext } from '../../Context/AppContext';

const EditarExercicio = () => {
  const { data, request, loading, error } = useFetch();
  const token = window.localStorage.getItem('token');
  const [validate, setValidate] = React.useState(true);
  const { setNotify } = React.useContext(AppContext);
  const nome = useForm();
  const codigo = useForm();
  const [especialidades, setEspecialidades] = React.useState([]);
  const [objetivos, setObjetivos] = React.useState([]);
  const [dificuldades, setDificuldades] = React.useState([]);
  const [esforco, setEsforco] = React.useState([]);
  const [equipamentos, setEquipamentos] = React.useState([]);
  const [grupamento, setGrupamento] = React.useState([]);

  const path = window.location.pathname;
  const exID = Number(path.replace(/\/$/, '').split('/').pop());

  async function handleSubmit(e) {
    e.preventDefault();
    setValidate(true);

    let esp = [];
    especialidades.forEach((element) => esp.push(element.value));
    let obj = [];
    objetivos.forEach((element) => obj.push(element.value));
    let dif = [];
    dificuldades.forEach((element) => dif.push(element.value));
    let esf = [];
    esforco.forEach((element) => esf.push(element.value));
    let equip = [];
    equipamentos.forEach((element) => equip.push(element.value));
    let grup = [];
    grupamento.forEach((element) => grup.push(element.value));

    if (nome.validate() && codigo.validate()) {
      const body = {
        nome: nome.value,
        codigo_video: codigo.value,
        especialidades: esp,
        objetivo: obj,
        dificuldade: dif,
        esforco: esf,
        equipamento: equip,
        grupamento: grup,
      };

      const { url, options } = EXERCICIO_PUT({ exID, body, token });
      const { response, json } = await request(url, options);
      //  console.log(json);
      if (response.ok) {
        setNotify({
          type: 'success',
          message: 'Exercício Alterado.',
        });
      } else {
        setNotify({
          type: 'error',
          message: 'Houve um erro.',
        });
      }
    } else setValidate(false);
  }

  React.useEffect(() => {
    async function fetchEx() {
      console.log(exID);
      const { url, options } = EXERCICIO_EDIT_GET({ exID, token });
      const { response, json } = await request(url, options);
      console.log(response);
      console.log(json);

      let esp = [];
      json[0].especialidades.forEach((element) =>
        esp.push({ value: element.term_id, label: element.name }),
      );
      let obj = [];
      json[0].objetivos.forEach((element) =>
        obj.push({ value: element.term_id, label: element.name }),
      );
      let dif = [];
      json[0].dificuldades.forEach((element) =>
        dif.push({ value: element.term_id, label: element.name }),
      );
      let esf = [];
      json[0].esforco.forEach((element) =>
        esf.push({ value: element.term_id, label: element.name }),
      );
      let equip = [];
      json[0].equipamentos.forEach((element) =>
        equip.push({ value: element.term_id, label: element.name }),
      );
      let grup = [];
      json[0].grupamento.forEach((element) =>
        grup.push({ value: element.term_id, label: element.name }),
      );

      console.log(esp);

      nome.initialValue(json[0].nome);
      codigo.initialValue(json[0].codigo_video);
      setEspecialidades(esp);
      setObjetivos(obj);
      setDificuldades(dif);
      setEsforco(esf);
      setEquipamentos(equip);
      setGrupamento(grup);
    }
    fetchEx();
  }, []);

  return (
    <section className={styles.editarExercicio}>
      <Header page="editarExercicio" />
      <div className={styles.leftBar}>
        <h1 className={styles.title}>Editar Exercício</h1>
        <Input
          label="Nome do exercício"
          type="text"
          name="nome"
          color="#fff"
          labelColor="#fff"
          {...nome}
        />
      </div>
      <div className={styles.content}>
        <Input
          label="Insira o código do Vimeo"
          type="text"
          name="codigo"
          {...codigo}
        />
        <div className={styles.selectTerms}>
          <PickTax
            slug={'especialidades'}
            nome={'Modalidades'}
            value={especialidades}
            setValue={setEspecialidades}
          />
          <PickTax
            slug={'objetivos'}
            nome={'Objetivos'}
            value={objetivos}
            setValue={setObjetivos}
          />
          <PickTax
            slug={'dificuldades'}
            nome={'Dificuldades'}
            value={dificuldades}
            setValue={setDificuldades}
          />
          <PickTax
            slug={'esforco'}
            nome={'Esforço'}
            value={esforco}
            setValue={setEsforco}
          />
          <PickTax
            slug={'equipamentos'}
            nome={'Equipamentos'}
            value={equipamentos}
            setValue={setEquipamentos}
          />
          <PickTax
            slug={'grupamento'}
            nome={'Grupamento Muscular'}
            value={grupamento}
            setValue={setGrupamento}
          />
        </div>

        {loading ? (
          <Button disabled>Atualizando...</Button>
        ) : (
          <Button onClick={handleSubmit}>Atualizar Exercício</Button>
        )}
        <Error message={error} />
        {!validate && (
          <div style={{ width: '100%' }}>
            <Error message={'Preencha corretamente os campos'} />
          </div>
        )}
      </div>
    </section>
  );
};

export default EditarExercicio;
