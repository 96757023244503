import React from 'react';
import { EXERCICIOS_GET, EXERCICIO_GET, GRUPO_GET } from '../../../api';
import Loading from '../../Helper/Loading';
import useFetch from '../../Hooks/useFetch';
import styles from './ManageAreaTreino.module.css';
import TreinoItem from './TreinoItem';
import { ReactSortable } from 'react-sortablejs';
import { UserContext } from '../../Context/UserContext';
import { ModalContext } from '../../Context/ModalContext';
import { AppContext } from '../../Context/AppContext';

const sortableOptions = {
  animation: 150,
  fallbackOnBody: true,
  swapThreshold: 0.65,
  handle: '.move',
};

const ManageAreaTreino = ({ searchField, selected, setSelected }) => {
  const { itensExercicio, setItensExercicio } = React.useContext(ModalContext);

  const { userID } = React.useContext(UserContext);

  const token = window.localStorage.getItem('token');

  const { data, loading, error, request } = useFetch();

  const { setNotify } = React.useContext(AppContext);

  const page = 1;
  const term = '';
  const nItens = -1;
  const q = '';

  if (window.localStorage.getItem('nptRascunho') === null) {
    window.localStorage.setItem('nptRascunho', JSON.stringify([]));
  }
  const [rascunhos, setRascunhos] = React.useState(
    JSON.parse(window.localStorage.getItem('nptRascunho')),
  );

  //console.log(rascunhos);

  const initialValue = [
    {
      atributos: [
        { label: 'Séries', value: ' -', id: 1 },
        { label: 'Repetições', value: ' -', id: 2 },
        { label: 'Fazer Por', value: ' -', id: 3 },
        { label: 'Manter Por', value: ' -', id: 4 },
        { label: 'Descanso', value: ' -', id: 5 },
        { label: 'Carga', value: ' -', id: 6 },
        { label: 'Elástico', value: ' -', id: 7 },
      ],
    },
  ];

  const fetchMyAPI = React.useCallback(async () => {
    const { url, options } = EXERCICIOS_GET({
      page,
      term,
      nItens,
      token,
      q,
      rascunhos,
    });
    const { json } = await request(url, options);
    //console.log(json);

    //pega o json e insere o array de parametros
    let tempArr = json.map((item) => {
      return [{ dados: item }, ...initialValue];
    });
    //console.log(tempArr);
    setItensExercicio(tempArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  React.useEffect(() => {
    if (rascunhos.length !== 0) {
      fetchMyAPI();
    }
  }, [fetchMyAPI]);

  //fetch quando escolhe um exercicio na busca
  React.useEffect(() => {
    async function fetchSearch() {
      if (searchField) {
        //console.log(searchField);
        if (searchField.post_type === 'exercicios') {
          const exID = searchField.id;

          const { url, options } = EXERCICIO_GET({ exID, token });
          const { json } = await request(url, options);

          //Checa se o valor já exsite antes de adicionar
          const isFound = itensExercicio.some((element) => {
            //console.log(element[0]);
            if (Number(element[0].dados.id) === Number(json[0].id)) {
              return true;
            }
            return false;
          });

          if (isFound) {
            console.log('✅ Exercício já foi adicionado');
            setNotify({
              type: 'error',
              message: 'Exercício já adicionado',
            });
          } else {
            //console.log(itensExercicio);
            let tempArr = json.map((item) => {
              return [{ dados: item }, ...initialValue];
            });
            // console.log(tempArr);
            //setItensExercicio(tempArr);
            setItensExercicio((itensExercicio) => [
              ...itensExercicio,
              tempArr[0],
            ]);
            //const teste = [...itensExercicio, tempArr[0]];
            //console.log(itensExercicio);
            //console.log(teste);
          }
          //---SE FOR GRUPO-----
        } else {
          const grupoID = searchField.id;
          // console.log(searchField);
          const { url, options } = GRUPO_GET({ grupoID, userID, token });
          const { json } = await request(url, options);

          //Checa se o valor já exsite antes de adicionar
          const isFound = itensExercicio.some((element) => {
            if (Number(element[0].dados.id) === Number(json[0].id)) {
              return true;
            }
            return false;
          });

          if (isFound) {
            console.log('✅ Grupo já foi adicionado');
            setNotify({
              type: 'error',
              message: 'Exercício já adicionado',
            });
          } else {
            //console.log(itensExercicio);
            let tempArr = json.map((item) => {
              return [{ dados: item }, ...initialValue];
            });
            console.log(tempArr);
            //setItensExercicio(tempArr);
            setItensExercicio((itensExercicio) => [
              ...itensExercicio,
              tempArr[0],
            ]);
            //const teste = [...itensExercicio, tempArr[0]];
            //console.log(teste);
          }
        }
      }
    }
    fetchSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField]);

  if (error) console.log(error);
  if (loading) return <Loading type="default" />;

  if (data)
    return (
      <ul className={styles.manageArea}>
        <ReactSortable
          list={itensExercicio}
          setList={setItensExercicio}
          {...sortableOptions}
        >
          {itensExercicio.map((item, index) => (
            <TreinoItem
              key={item[0].dados.id}
              item={item}
              selected={selected}
              setSelected={setSelected}
              index={index}
            />
          ))}
        </ReactSortable>
      </ul>
    );
  else
    return <h1 className={styles.noData}>Adicione exercícios para começar.</h1>;
};

export default ManageAreaTreino;
