import React from 'react';
import styles from './EditarUsuario.module.css';
import Header from '../../Header/Header';
import Input from '../../Forms/Input';
import useForm from '../../Hooks/useForm';
import Button from '../../Forms/Button';
import Error from '../../Helper/Error';
import TextArea from '../../Forms/TextArea';
import useFetch from '../../Hooks/useFetch';
import { AppContext } from '../../Context/AppContext';
import { UserContext } from '../../Context/UserContext';
import { USER_PUT, USER_GET_BY_ID } from '../../../api';
import Loading from '../../Helper/Loading';

const EditarUsuario = () => {
  const token = window.localStorage.getItem('token');
  const { data, loading, error, request } = useFetch();
  const { userID, userType } = React.useContext(UserContext);
  const { setNotify } = React.useContext(AppContext);
  const nome = useForm();
  const sobrenome = useForm();
  const email = useForm('email');
  const whatsapp = useForm('phone');
  const cref = useForm();
  const [erro, setErro] = React.useState('');
  const [dataUser, setDataUser] = React.useState('');

  const [preview, setPreview] = React.useState();
  const [file, setFile] = React.useState();
  const [loadingPic, setLoadingPic] = React.useState(false);

  const validaFields = () => {
    setErro('');
    if (userType === 'master' || userType === 'instrutor') {
      if (
        nome.validate() &&
        sobrenome.validate() &&
        email.validate() &&
        whatsapp.validate() &&
        cref.validate()
      ) {
        console.log(2);
        return true;
      }
    } else {
      if (
        nome.validate() &&
        sobrenome.validate() &&
        email.validate() &&
        whatsapp.validate()
      ) {
        console.log(2);
        return true;
      }
    }
    console.log(3);
    return false;
  };

  //---BOTÃO DE ENVIO DE EMAIL
  async function handleClick(e) {
    e.preventDefault();
    setErro('');

    const body = {
      ...dataUser,
      nome: nome.value,
      sobrenome: sobrenome.value,
      email: email.value,
      whatsapp: whatsapp.value,
      cref: cref.value,
    };

    if (validaFields()) {
      try {
        const alunoID = userID;
        const { url, options } = USER_PUT({ token, body, alunoID });
        const { response } = await request(url, options);
        console.log(response);
        if (response.ok) {
          setNotify({
            type: 'success',
            message: 'Usuário alterado.',
          });
        } else {
          setNotify({
            type: 'error',
            message: 'Usuário já existe.',
          });
        }
      } catch (err) {
        setErro(err.message);
      } finally {
        setErro('');
      }
    } else {
      setErro('Preencha os campos corretamente.');
    }
  }

  function handleChange(e) {
    setErro('');
    if (e.target.files[0]) {
      if (e.target.files[0].size > 5000000) {
        setErro('A imagem deve ter no máx 5mb');
      } else {
        setFile(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]));
      }
    }
  }

  const handleSubmit = () => {
    setLoadingPic(true);
    const formData = new FormData();
    formData.append('files', file);

    async function fetchPic() {
      fetch('https://api.nascidosparatreinar.com/json/api/user_image', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          setNotify({
            type: 'success',
            message: 'Foto alterada.',
          });
          setLoadingPic(false);
        })
        .catch((error) => {
          setNotify({
            type: 'error',
            message: 'Houve um Erro.',
          });
        });
    }
    fetchPic();
  };

  //--FETCH USER
  React.useEffect(() => {
    async function fetchUser() {
      if (userID) {
        const theID = userID;
        const { url, options } = USER_GET_BY_ID({ token, theID });
        const { json } = await request(url, options);
        console.log(json);
        nome.initialValue(json.nome);
        sobrenome.initialValue(json.sobrenome);
        email.initialValue(json.email);
        whatsapp.initialValue(json.whatsapp);
        cref.initialValue(json.cref);
        setPreview(json.photo);
        setDataUser(json);
      }
    }
    fetchUser();
  }, [userID]);

  /*   if (loading) return <Loading type="default" />;
  if (data) */
  return (
    <section className={styles.editarUsuario}>
      <Header page="editarUsuario" />
      <div className={styles.leftBar}>
        <h1 className={styles.title}>Editar Usuário</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.basics}>
          <Input label="Nome" type="text" name="nome" {...nome} />
          <Input
            label="Sobrenome"
            type="email"
            name="sobrenome"
            {...sobrenome}
          />
          <Input label="Email" type="email" nome="email" {...email} />
          <Input label="Whatsapp" type="email" nome="email" {...whatsapp} />
        </div>
        {dataUser.type === 'instrutor' ||
          (dataUser.type === 'master' && (
            <div className={styles.instrutor}>
              <Input label="Cref" type="text" nome="cref" {...cref} />
            </div>
          ))}
        <div className={styles.btEnviar}>
          {loading ? (
            <Button disabled>Aguarde...</Button>
          ) : (
            <Button onClick={handleClick}>Alterar Usuário</Button>
          )}
          {!validaFields.value && <Error message={erro} />}
        </div>
        <div className={styles.picContent}>
          {preview && (
            <div
              className={styles.preview}
              style={{ backgroundImage: `url('${preview}'` }}
            ></div>
          )}
          <input
            type="file"
            id="file"
            className={styles.inputFile}
            name="file"
            onChange={handleChange}
          />
          <label htmlFor="file">
            <span>Escolha a foto</span>
          </label>
          {loadingPic ? (
            <Button disabled>Atualizando...</Button>
          ) : (
            <Button onClick={handleSubmit}>Atualizar Foto</Button>
          )}
          <Error message={erro} />
        </div>
      </div>
    </section>
  );
};

export default EditarUsuario;
