import React from 'react';
import { Link } from 'react-router-dom';
import useForm from '../../Hooks/useForm';
import useFetch from '../../Hooks/useFetch';
import Button from '../../Forms/Button';
import Input from '../../Forms/Input';
import Error from '../../Helper/Error';
import styles from './LostForm.module.css';
import { PASSWORD_LOST } from '../../../api';

const LostForm = () => {
  const email = useForm('email');
  const { data, request, error, loading } = useFetch();
  const [status, setStatus] = React.useState('');
  //Função do botão click
  async function handleSubmit(event) {
    event.preventDefault();

    if (email.validate()) {
      const { url, options } = PASSWORD_LOST({
        email: email.value,
        url: window.location.href.replace('perdeu', 'resetar'),
      });
      const { response } = await request(url, options);
      if (response.ok) {
        setStatus('Email enviado com sucesso! Check também seu spam.');
      }
    }
  }

  return (
    <section className="animeLeft">
      <div className={styles.logo}></div>
      <h1 className="title">Troque sua Senha</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Input label="Email" type="text" name="email" {...email} />
        {loading ? (
          <Button disabled>Carregando...</Button>
        ) : (
          <Button>Enviar</Button>
        )}
        <Error message={error} />
        <p style={{ marginTop: '20px' }}></p>
        {status}
      </form>
      <Link className={styles.perdeu} to="/login">
        Voltar
      </Link>
    </section>
  );
};

export default LostForm;
