import React from 'react';
import styles from './TreinoItem.module.css';
import Video from '../ListarExercicios/Video';
import { ModalContext } from '../../Context/ModalContext';

const TreinoItem = ({ item, selected, setSelected, index }) => {
  // eslint-disable-next-line
  const [values, setValues] = React.useState(item[1].atributos);
  const [dados, setDados] = React.useState(item[0].dados);
  const {
    setModal,
    setDataModal,
    setItensExercicio,
    itensExercicio,
    setTypeModal,
    setNitem,
  } = React.useContext(ModalContext);
  const [repeatGroup, setRepeatGroup] = React.useState(
    item[0].dados.repeticoes,
  );

  React.useEffect(() => {
    setValues(item[1].atributos);
    setDados(item[0].dados);
  }, [item]);

  React.useEffect(() => {
    setSelSelf(false);
    setSelected([]);
  }, [itensExercicio]);

  React.useEffect(() => {
    if (selected.length === itensExercicio.length) {
      setSelSelf(true);
    } else if (selected.length === 0) {
      setSelSelf(false);
    }
  }, [selected]);

  //CHANGE REPETIÇÕES
  React.useEffect(() => {
    const repeat = [...itensExercicio];
    repeat[index][0].dados.repeticoes = repeatGroup;
    setItensExercicio(() => repeat);
    //console.log(repeatGroup);
  }, [repeatGroup]);

  const [selSelf, setSelSelf] = React.useState(false);

  //funcão toogle da selecao dos itens
  const removeAtIndex = (arr, index) => {
    const copy = [...arr];
    copy.splice(index, 1);
    return copy;
  };

  const toggle = (arr, item, getValue = (item) => item) => {
    const index = arr.findIndex((i) => getValue(i) === getValue(item));
    if (index === -1) return [...arr, item];
    return removeAtIndex(arr, index);
  };

  if (dados.post_type === 'exercicios')
    return (
      <li className={`${styles.item}  ${selSelf && styles.itemSelected}`}>
        <div className={styles.image}>
          <Video codigo={dados.codigo_video} size="240p" controls={false} />
          <div
            className={`${styles.overColor} ${
              selSelf && styles.overColorSelected
            }`}
          >
            <span className={styles.values}>
              {values &&
                values.map((item) => (
                  <p key={item.id}>
                    {item.label}: {item.value}
                  </p>
                ))}
            </span>
          </div>
        </div>
        <div className={styles.infos}>
          <h3 className={styles.titulo}>{dados.nome}</h3>
          <div className={`${styles.buttons} ${styles.borderTop}`}>
            <span
              className={`${styles.icon} ${styles.selectExercicio} ${
                selSelf && styles.itemSelectedIcon
              }`}
              onClick={() => {
                //console.log('Adicionou');
                setSelSelf(!selSelf);
                const tempArr = selected;
                const selFinal = toggle(tempArr, dados.id);
                setSelected(selFinal);
                //console.log(JSON.stringify(selected));
                //console.log(selected);
              }}
            ></span>
            <span
              className={`${styles.icon} ${styles.move}  move`}
              onClick={() => {
                //console.log('moveu');
              }}
            ></span>
            <span
              className={`${styles.icon} ${styles.removeExercicio}`}
              onClick={() => {
                //setSelected([]);
                setSelSelf(false);
                setItensExercicio((current) =>
                  current.filter((itensExercicio) => {
                    // 👇️ remove object that has id equal to 2
                    return itensExercicio[0].dados.id !== dados.id;
                  }),
                );
                let storage = [];
                storage =
                  JSON.parse(window.localStorage.getItem('nptRascunho')) || [];
                let filterRemoved = storage.filter((e) => e !== dados.id);
                localStorage.setItem(
                  'nptRascunho',
                  JSON.stringify(filterRemoved),
                );
              }}
            ></span>
          </div>
        </div>
      </li>
    );
  //console.log(grade);
  else {
    function isJson(str) {
      try {
        return JSON.parse(str);
      } catch (e) {
        return str;
      }
    }

    let grade = isJson(dados.grade_exercicios);
    //console.log(item);
    //const grade = JSON.parse(dados.grade_exercicios);

    return (
      <li className={styles.item}>
        <div className={styles.grupos}>
          <div className={styles.tituloGrupo}>
            <h2>GRUPO</h2>
            <p>{dados.nome}</p>
          </div>
        </div>
        <div className={styles.contentGrupos}>
          {grade &&
            grade.map((item) => (
              <p key={item[0].dados.id}>{item[0].dados.nome}</p>
            ))}
        </div>
        <div className={styles.repeat}>
          <p>Repetições:</p>
          <input
            type="number"
            min="1"
            max="10"
            value={repeatGroup}
            onChange={(e) => setRepeatGroup(e.target.value)}
          ></input>
        </div>

        <div className={styles.infos}>
          <div className={styles.buttons}>
            <span
              className={`${styles.icon} ${styles.editGrupo}`}
              onClick={() => {
                setModal(true);
                //console.log(item[0].dados);
                setDataModal(grade);
                setNitem(index);
                //editGrade();
                setTypeModal('editGrupo');
              }}
            ></span>
            <span
              className={`${styles.icon} ${styles.move}  move`}
              onClick={() => {
                //console.log('moveu');
              }}
            ></span>
            <span
              className={`${styles.icon} ${styles.removeExercicio}`}
              onClick={() => {
                //setSelected([]);
                setItensExercicio((current) =>
                  current.filter((itensExercicio) => {
                    // 👇️ remove object that has id equal to 2
                    return itensExercicio[0].dados.id !== dados.id;
                  }),
                );
              }}
            ></span>
          </div>
        </div>
      </li>
    );
  }
};

export default TreinoItem;
