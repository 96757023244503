import React from 'react';
import styles from './StatsGraph.module.css';
import { VictoryBar, VictoryChart, VictoryTheme } from 'victory';

const StatsGraph = ({ dados, color }) => {
  return (
    <div>
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={{ x: 10 }}
        minDomain={{ x: 0 }}
        maxDomain={{ x: 31 }}
        width={1000}
      >
        <VictoryBar
          alignment="start"
          data={dados}
          style={{ data: { fill: color } }}
          labels={({ datum }) => datum.x}
          animate={{
            duration: 2000,
            onLoad: { duration: 1000 },
          }}
        ></VictoryBar>
      </VictoryChart>
    </div>
  );
};

export default StatsGraph;
