import React from 'react';
import { Link } from 'react-router-dom';
import styles from './MainMenu.module.css';
import UserContext from '../Context/UserContext';

const MainMenu = () => {
  const { userType } = React.useContext(UserContext);
  const [menuAtivo, setMenuAtivo] = React.useState(false);

  function handleMenuIcon() {
    setMenuAtivo(!menuAtivo);
    //console.log(menuAtivo);
  }

  if (userType === 'master')
    return (
      <div className={styles.mainMenu}>
        <div className={styles.menuIcon} onClick={handleMenuIcon}></div>
        <nav
          className={`${styles.menuContainer} ${menuAtivo && styles.menuAtivo}`}
        >
          <ul>
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/adicionar-exercicio" onClick={handleMenuIcon}>
                ADICIONAR EXERCÍCIO
              </Link>
            </li>
            <li>
              <Link to="/adicionar-usuario" onClick={handleMenuIcon}>
                ADCIONAR USUÁRIO
              </Link>
            </li>
            <li>
              <Link to="/manager-area" onClick={handleMenuIcon}>
                BANNER ÁREA
              </Link>
            </li>
            <li>
              <Link to="/adicionar-grupo" onClick={handleMenuIcon}>
                CRIAR GRUPO
              </Link>
            </li>
            <li>
              <Link to="/adicionar-treino" onClick={handleMenuIcon}>
                CRIAR TREINO
              </Link>
            </li>
            <li>
              <Link to="/editar-categorias" onClick={handleMenuIcon}>
                EDITAR CATEGORIAS
              </Link>
            </li>
            <li>
              <Link to="/listar-exercicios" onClick={handleMenuIcon}>
                LISTAR EXERCÍCIOS
              </Link>
            </li>
            <li>
              <Link to="/listar-usuarios" onClick={handleMenuIcon}>
                LISTAR USUÁRIOS
              </Link>
            </li>
            <li>
              <Link to="/listar-treinos" onClick={handleMenuIcon}>
                MEUS TREINOS
              </Link>
            </li>
            <li>
              <Link to="/listar-grupos" onClick={handleMenuIcon}>
                MEUS GRUPOS
              </Link>
            </li>
            <li>
              <Link to="/ranking" onClick={handleMenuIcon}>
                RANKING
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    );

  if (userType === 'instrutor')
    return (
      <div className={styles.mainMenu}>
        <div className={styles.menuIcon} onClick={handleMenuIcon}></div>
        <nav
          className={`${styles.menuContainer} ${menuAtivo && styles.menuAtivo}`}
        >
          <ul>
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/adicionar-usuario" onClick={handleMenuIcon}>
                ADCIONAR USUÁRIO
              </Link>
            </li>
            <li>
              <Link to="/adicionar-grupo" onClick={handleMenuIcon}>
                CRIAR GRUPO
              </Link>
            </li>
            <li>
              <Link to="/adicionar-treino" onClick={handleMenuIcon}>
                CRIAR TREINO
              </Link>
            </li>
            <li>
              <Link to="/listar-exercicios" onClick={handleMenuIcon}>
                LISTAR EXERCÍCIOS
              </Link>
            </li>
            <li>
              <Link to="/listar-usuarios" onClick={handleMenuIcon}>
                LISTAR USUÁRIOS
              </Link>
            </li>
            <li>
              <Link to="/listar-treinos" onClick={handleMenuIcon}>
                MEUS TREINOS
              </Link>
            </li>
            <li>
              <Link to="/listar-grupos" onClick={handleMenuIcon}>
                MEUS GRUPOS
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    );

  if (userType === 'aluno1' || userType === 'aluno2') return null;
};

export default MainMenu;
