import React from 'react';
import styles from './TextParq.module.css';

const TextParq = () => {
  return (
    <div className={styles.textParq}>
      <h1>Questionário de Prontidão para Atividade Física (PAR-Q)</h1>
      <p>
        Este questionário tem o objetivo de identificar a necessidade de
        avaliação por um médico antes do início da atividade física. Caso você
        responda “SIM” a uma ou mais perguntas, converse com seu médico ANTES de
        aumentar seu nível atual de atividade física. Mencione este questionário
        e as perguntas às quais você respondeu “SIM”.
        <br />
        <br />
        <strong>
          Por favor, responda “SIM” ou “NÃO” às seguintes perguntas:
        </strong>
      </p>
      <ul>
        <li>
          <p>
            1 - Algum médico já disse que você possui algum problema de coração
            e que só deveria realizar atividade física supervisionado por
            profissionais de saúde?
          </p>
          <span>SIM | NÃO</span>
        </li>
        <li>
          <p>2 - Você sente dores no peito quando pratica atividade física?</p>
          <span>SIM | NÃO</span>
        </li>
        <li>
          <p>
            3 - No último mês, você sentiu dores no peito quando praticou
            atividade física?
          </p>
          <span>SIM | NÃO</span>
        </li>
        <li>
          <p>
            4 - Você apresenta desequilíbrio devido à tontura e/ ou perda de
            consciência?
          </p>
          <span>SIM | NÃO</span>
        </li>
        <li>
          <p>
            5 - Você possui algum problema ósseo ou articular que poderia ser
            piorado pela atividade física?
          </p>
          <span>SIM | NÃO</span>
        </li>
        <li>
          <p>
            6 - Você toma atualmente algum medicamento para pressão arterial
            e/ou problema de coração?
          </p>
          <span>SIM | NÃO</span>
        </li>
        <li>
          <p>
            7 - Sabe de alguma outra razão pela qual você não deve praticar
            atividade física?
          </p>
          <span>SIM | NÃO</span>
        </li>
      </ul>
      <p>
        Se você respondeu “SIM” a uma ou mais perguntas, seria recomendado uma
        avaliação de um médico antes do início do programa. Leia e assinale o
        “Termo de Responsabilidade para Prática de Atividade Física”
      </p>
      <h2>Termo de Responsabilidade para Prática de Atividade Física</h2>
      <p>
        Estou ciente de que é recomendável conversar com um médico antes de
        aumentar meu nível atual de atividade física, por ter respondido “SIM” a
        uma ou mais perguntas do “Questionário de Prontidão para Atividade
        Física” (PAR-Q). Assumo plena responsabilidade por qualquer atividade
        física praticada sem o atendimento a essa recomendação.
      </p>
    </div>
  );
};

export default TextParq;
