import React from 'react';
import LostForm from './LostForm';
import styles from './LostForm.module.css';

const LoginPasswordLost = () => {
  return (
    <section className={styles.login}>
      <div className={styles.form}>
        <LostForm />
      </div>
    </section>
  );
};

export default LoginPasswordLost;
