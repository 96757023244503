import React from 'react';
import styles from './BoxRanking2.module.css';

const BoxRanking = ({ foto, nome, instrutor, pontos, colocacao, userType }) => {
  return (
    <div className={styles.box}>
      <img
        src={
          foto === ''
            ? 'https://api.nascidosparatreinar.com/wp-content/themes/api/images/placeholder-id.png'
            : foto
        }
        alt="foto"
        className={styles.image}
      />
      <span>
        <label className={styles.label}>ALUNO</label>
        <p className={styles.nome}>{nome}</p>
        <label className={styles.label}>{pontos} PONTOS</label>
      </span>
      <span className={styles.colocacao}>{colocacao + 1}</span>
    </div>
  );
};

export default BoxRanking;
