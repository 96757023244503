import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './RightArea.module.css';
import UserContext from '../Context/UserContext';
import useFetch from '../Hooks/useFetch';
import { INSTRUTOR_CREF_GET, USER_GET_BY_ID } from '../../api';
import { AppContext } from '../Context/AppContext';
import Parser from 'html-react-parser';

const RightArea = ({ page }) => {
  const token = window.localStorage.getItem('token');
  const { counter, setCounter } = React.useContext(AppContext);
  const { userType, userLogout, userID } = React.useContext(UserContext);
  const { data, request } = useFetch();
  const [modal, setModal] = React.useState(false);
  const [author, setAuthor] = React.useState();
  const [authorID, setAuthorID] = React.useState();
  const [message, setMessage] = React.useState();
  let navigate = useNavigate();

  React.useEffect(() => {
    async function fetchUser() {
      const theID = userID;
      const { url, options } = USER_GET_BY_ID({ token, theID });
      const { response, json } = await request(url, options);
      setMessage(json.treino_atual.mensagem);
      setAuthorID(json.author);
    }
    fetchUser();
  }, []);

  React.useEffect(() => {
    if (authorID) {
      //console.log(authorID);
      async function fetchAuthor() {
        const theID = authorID;
        const { url, options } = INSTRUTOR_CREF_GET({ token, theID });
        const { response, json } = await request(url, options);
        setAuthor(json);
        //console.log(response);
        // console.log(json);
      }
      fetchAuthor();
    }
  }, [authorID]);

  if (page === 'aluno')
    return (
      <div className={styles.rightArea}>
        {modal && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <div className={styles.icon_msg}></div>
              {authorID && (
                <p className={styles.msg}>
                  {Parser(message.replace(/\r?\n/g, '<br />'))}
                </p>
              )}
            </div>
            <div className={styles.close} onClick={() => setModal(false)}></div>
          </div>
        )}
        <div
          className={styles.icon + ' ' + styles.home}
          onClick={() => {
            document.location.reload(true);
          }}
        ></div>
        <div
          className={styles.icon + ' ' + styles.message}
          onClick={() => {
            setModal(true);
          }}
        ></div>
        <div
          className={styles.icon + ' ' + styles.whatsapp}
          onClick={() => {
            window.open(
              'https://api.whatsapp.com/send?phone=' + author.whatsapp,
              '_blank',
            );
          }}
        ></div>
        <div
          className={styles.icon + ' ' + styles.config}
          onClick={() => {
            navigate('/editar-usuario');
          }}
        ></div>
        <div
          className={styles.icon + ' ' + styles.logout}
          onClick={() => {
            userLogout();
          }}
        ></div>
      </div>
    );
  //-------------------------------------------------------------------------------//
  else
    return (
      <div className={styles.rightArea}>
        <div
          className={styles.icon + ' ' + styles.arrowBack}
          onClick={() => {
            navigate(-1);
          }}
        ></div>
        {counter > 0 && (
          <div
            className={styles.icon + ' ' + styles.counter}
            onClick={() => {
              let arr = window.location.href.split('/');
              var last = arr[arr.length - 1] || arr[arr.length - 2];
              //console.log(last);
              if (last !== 'adicionar-treino') {
                navigate('/adicionar-treino');
              }
            }}
          >
            {counter}
          </div>
        )}
        <div
          className={styles.icon + ' ' + styles.home}
          onClick={() => {
            navigate('/');
          }}
        ></div>
        <div
          className={styles.icon + ' ' + styles.config}
          onClick={() => {
            navigate('/editar-usuario');
          }}
        ></div>
        <div
          className={styles.icon + ' ' + styles.logout}
          onClick={() => {
            userLogout();
          }}
        ></div>
      </div>
    );
};

export default RightArea;
