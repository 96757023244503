import React from 'react';
import Footer from '../../Footer';
import Header from '../../Header/Header';
import FeedExercicios from './FeedExercicios';
import styles from './ListarExercicios.module.css';
import TermsBar from './TermsBar';

const ListarExercicios = () => {
  const [pages, setPages] = React.useState([1]);
  const [term, setTerm] = React.useState('');
  const [q, setQ] = React.useState('');
  const [oldTerm, setOldTerm] = React.useState('');
  const [endPages, setEndPages] = React.useState(false);

  //Compara e limpa as pagínas quando é escolhido um novo termo
  React.useEffect(() => {
    if (term !== oldTerm) {
      window.scrollTo(0, 0);
      setPages([1]);
      setOldTerm(term);
      setEndPages(false);
    }
    // Carrega nova página no 90% de rolagem, para e espera 500ms para carregar novamente
    let wait = false;
    // console.log(statusLoading);
    function infinitScroll() {
      if (!endPages) {
        const scroll = window.scrollY;
        const height = document.body.offsetHeight - window.innerHeight;
        if (scroll > height * 0.9 && !wait) {
          setPages((pages) => [...pages, pages.length + 1]);
          // console.log(endPages);
          wait = true;
          setTimeout(() => {
            wait = false;
          }, 500);
        }
      }
    }

    window.addEventListener('wheel', infinitScroll);
    window.addEventListener('scroll', infinitScroll);
    return () => {
      window.removeEventListener('wheel', infinitScroll);
      window.removeEventListener('scroll', infinitScroll);
    };
  }, [term, oldTerm, endPages]);

  return (
    <section className={styles.listarExercicios}>
      <Header />
      <TermsBar setTerm={setTerm} setQ={setQ} term={term} />
      {pages.map((page) => (
        <FeedExercicios
          key={page}
          page={page}
          term={term}
          nItens={12}
          setEndPages={setEndPages}
          endPages={endPages}
          q={q}
        />
      ))}
      <Footer />
    </section>
  );
};

export default ListarExercicios;
