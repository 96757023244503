import React from 'react';
import styles from './TextArea.module.css';

const TextArea = ({ obs, setObs, label }) => {
  return (
    <div className={styles.wrapper}>
      <label htmlFor={obs} className={styles.label}>
        {label}
      </label>
      <textarea
        className={styles.obs}
        value={obs}
        name="obs"
        rows="5"
        onChange={({ target }) => setObs(target.value)}
      />
    </div>
  );
};

export default TextArea;
