import React from 'react';
import styles from './MiddleArea.module.css';
import UserContext from '../Context/UserContext';
import useFetch from '../Hooks/useFetch';
import { INSTRUTOR_CREF_GET, USER_GET_BY_ID } from '../../api';

const MiddleArea = () => {
  const token = window.localStorage.getItem('token');
  const { userType, userName, userID } = React.useContext(UserContext);
  //console.log(userType);
  const { request } = useFetch();
  const [aluno, setAluno] = React.useState();
  const [authorID, setAuthorID] = React.useState();
  const [author, setAuthor] = React.useState();

  React.useEffect(() => {
    async function fetchUser() {
      if (userID) {
        const theID = userID;
        const { url, options } = USER_GET_BY_ID({ token, theID });
        const { response, json } = await request(url, options);
        setAluno(json);
        //console.log(json);
        //console.log(json.author);
        setAuthorID(json.author);
      }
    }
    fetchUser();
  }, [userID]);

  React.useEffect(() => {
    if (authorID) {
      //console.log(authorID);
      async function fetchAuthor() {
        const theID = authorID;
        const { url, options } = INSTRUTOR_CREF_GET({ token, theID });
        const { response, json } = await request(url, options);
        setAuthor(json);
        //console.log(response);
        //console.log(json);
      }
      fetchAuthor();
    }
  }, [authorID]);

  let desc = '';
  if (userType === 'master') {
    desc = 'Usuário Master';
  } else if (userType === 'instrutor') {
    desc = 'Instrutor';
  } else if (userType === 'aluno1') {
    desc = 'Aluno';
  } else if (userType === 'aluno2') {
    desc = 'Aluno';
  }

  if (userType === 'master' || userType === 'instrutor')
    return (
      <div className={styles.middleArea}>
        <span>
          {userName && <h2>Olá, {userName}</h2>}
          <p>{desc}</p>
        </span>
      </div>
    );
  else {
    /*-------------------------------------------------------------------*/
    return (
      <div className={styles.middleArea}>
        <div className={styles.userPhotoArea}>
          {aluno && (
            <div
              className={styles.image}
              style={{ backgroundImage: ` url('${aluno.photo}')` }}
            ></div>
          )}
          <div className={styles.dados}>
            <div className={styles.pontos}>{aluno && aluno.pontuacao} </div>
          </div>
        </div>
        <div className={styles.displayName}>
          <span>
            {userName && <h2>Olá, {userName}</h2>}
            <p>{desc}</p>
          </span>
          <span className={styles.instrutor}>
            {author && <p>Instrutor: {author.nome}</p>}
            {author && <p>Cref: {author.cref}</p>}
          </span>
        </div>
      </div>
    );
  }
};

export default MiddleArea;
