import React from 'react';
import styles from './Modal.module.css';
import { ModalContext } from '../Context/ModalContext';
import SelectEditGrup from './SelectEditGroup';
import Button from './../Forms/Button';

const Modal = () => {
  const { modal, setModal, dataModal, setDataModal, typeModal } =
    React.useContext(ModalContext);

  //console.log(dataModal);

  if (modal)
    return (
      <div className={styles.modal}>
        <div className={styles.container}>
          {dataModal.map((item, index) => (
            <div key={item[0].dados.id} className={styles.grupoItem}>
              <h3>{item[0].dados.nome}</h3>
              <SelectEditGrup
                atributos={item[1].atributos}
                dataModal={dataModal}
                setDataModal={setDataModal}
                index={index}
              />
            </div>
          ))}
          <div className={styles.btAplicar}>
            <Button
              onClick={() => {
                setModal(false);
              }}
            >
              FECHAR
            </Button>
          </div>
        </div>
      </div>
    );
};

export default Modal;
