import React from 'react';
import styles from './Aniversario.module.css';

const Aniversario = ({ parabens }) => {
  if (parabens)
    return (
      <div className={styles.aniversario}>
        <img src="https://api.nascidosparatreinar.com/wp-content/uploads/2023/01/aniversario.jpg" />
      </div>
    );
};

export default Aniversario;
