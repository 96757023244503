import React from 'react';
import styles from './Input.module.css';

const Input = ({
  label,
  type,
  name,
  value,
  onChange,
  error,
  onBlur,
  color,
  labelColor,
  placeholder,
}) => {
  return (
    <div className={styles.wrapper}>
      <label
        htmlFor={name}
        className={styles.label}
        style={{ color: labelColor }}
      >
        {label}
      </label>
      <input
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={styles.input}
        style={{ backgroundColor: color }}
      />
      {error && (
        <p className={styles.error} style={{ color: labelColor }}>
          {error}
        </p>
      )}
    </div>
  );
};

export default Input;
