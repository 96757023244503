import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TOKEN_POST, TOKEN_VALIDATE_POST, USER_GET } from '../../api';

export const UserContext = React.createContext();

export const UserStorage = ({ children }) => {
  const [data, setData] = React.useState(null);
  const [login, setLogin] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [userType, setUserType] = React.useState(null);
  const [userID, setUserID] = React.useState(null);
  const [userImage, setUserImage] = React.useState(null);
  const [userName, setUserName] = React.useState(null);
  const [userStatus, setUserStatus] = React.useState(null);
  const [userAuthor, setUserAuthor] = React.useState(null);
  const [userForceActivate, setUserForceActivate] = React.useState(null);
  const navigate = useNavigate();

  async function getUser(token) {
    const { url, options } = USER_GET(token);
    const response = await fetch(url, options);
    const json = await response.json();
    // console.log(json);
    // console.log(response);
    //console.log(window.localStorage.getItem('token'));
    setData(json);
    setLogin(true);
    setUserType(json.type);
    setUserID(json.id);
    setUserName(json.nome);
    setUserImage(json.photo);
    setUserForceActivate(json.force_activate);
    setUserStatus(json.status);
    setUserAuthor(json.author);
    //redirectPage(json.type);
  }

  /*   function redirectPage(type) {
    //console.log(userType && userType);
    if (!login) {
      if (type === 'master') {
        navigate('/');
        console.log(1);
      } else if (type === 'aluno1') {
        navigate('/meu-treino');
        console.log(2);
      }
    }
  } */

  async function userLogin(username, password) {
    try {
      //Sempre seta error como null para iniciar
      setError(null);
      //Inicia o carregando...
      setLoading(true);
      const { url, options } = TOKEN_POST({ username, password });
      const response = await fetch(url, options);
      //console.log(response);
      if (!response.ok) throw new Error('Usuário Inválido');
      const { token } = await response.json();
      window.localStorage.setItem('token', token);
      await getUser(token);
      navigate('/');
    } catch (err) {
      setError(err.message);
      setLogin(false);
    } finally {
      setLoading(false);
    }
  }

  const userLogout = React.useCallback(
    async function () {
      setData(null);
      setError(null);
      setLoading(false);
      setLogin(false);
      window.localStorage.removeItem('token');
      navigate('/login');
      // alert('logout');
    },
    [navigate],
  );

  React.useEffect(() => {
    async function autoLogin() {
      const token = window.localStorage.getItem('token');
      if (token) {
        try {
          setError(null);
          setLoading(true);
          const { url, options } = TOKEN_VALIDATE_POST(token);
          const response = await fetch(url, options);
          if (!response.ok) throw new Error('Token Invalido');
          await getUser(token);

          //navigate('/');
        } catch (err) {
          console.log('aqui');
          userLogout();
        } finally {
          setLoading(false);
        }
      } else {
        setLogin(false);
      }
    }
    autoLogin();
  }, [userLogout]);

  return (
    <UserContext.Provider
      value={{
        userLogin,
        userLogout,
        data,
        error,
        loading,
        login,
        userType,
        userID,
        userName,
        userImage,
        userStatus,
        userForceActivate,
        userAuthor,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
