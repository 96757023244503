import React from 'react';
import { HOME_STATS_GET, INSTRUTOR_CREF_GET } from '../../../api';
import Header from '../../Header/Header';
import useFetch from '../../Hooks/useFetch';
import Video from '../ListarExercicios/Video';
import styles from './AlunoTreino.module.css';
import Weeks from './Weeks';
import BoxRanking from '../../Pages/Home/BoxRanking2';
import Loading from '../../Helper/Loading';
import Aniversario from './Aniversario';

const AlunoTreino = ({ alunoData }) => {
  //console.log(alunoData.nascimento);
  //console.log(dates['7-8-2022'] !== undefined);
  // console.log(dates);
  const { request, loading } = useFetch();
  const token = window.localStorage.getItem('token');

  const currentDate = new Date();
  const mes = String(currentDate.getMonth() + 1).padStart(2, '0');
  const ano = currentDate.getFullYear();
  const [userRanking, setUserRanking] = React.useState();

  const [calendario, setCalendario] = React.useState(alunoData.calendario);
  const [treinoWeek, setTreinoWeek] = React.useState(alunoData.treino_atual);
  const [currentDay, setCurrentDay] = React.useState('');
  const [instrutorData, setInstrutorData] = React.useState();
  const [instrutorType, setInstrutorType] = React.useState();
  const [instrutorVideo, setInstrutorVideo] = React.useState();
  const [instrutorBanner, setInstrutorBanner] = React.useState();
  const [homeStats, setHomeStats] = React.useState();
  const date = new Date();
  const [today, setToday] = React.useState(
    ('0' + date.getDate()).slice(-2) +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      date.getFullYear(),
  );

  const parabens =
    alunoData.nascimento.slice(0, 5) == today.slice(0, 5) ? true : false;
  //console.log(parabens);

  //Função pega a data inicial e final da semana e gera as datas intermediarias
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    const dates = [];
    while (date <= endDate) {
      dates.push(new Date(date).toLocaleDateString());
      date.setDate(date.getDate() + 1);
    }

    //troca / por -
    for (let i = 0; i < dates.length; i++) {
      dates[i] = dates[i].replace(/\//g, '-');
    }
    return dates;
  }

  //Gera a data inicial e final da semana e envia para funcao acima
  const curr = new Date();
  //const first = curr.getDate() - curr.getDay() + 1; // Start from Monday
  const first = curr.getDate() - curr.getDay(); // Start from Monday
  const firstDate = new Date(curr.setDate(first));
  const lastDate = new Date(curr.setDate(firstDate.getDate() + 6));
  const currentWeek = getDatesInRange(firstDate, lastDate);
  // console.log(currentWeek);

  React.useEffect(() => {
    const theID = alunoData.author;
    async function fetchInstrutor() {
      const { url, options } = INSTRUTOR_CREF_GET({ token, theID });
      const { json, response } = await request(url, options);
      //console.log(response);
      //console.log(json);
      setInstrutorData(json);
      setInstrutorType(json.type);
      setInstrutorVideo(json.video_aluno);
      setInstrutorBanner(json.banner_aluno);
    }
    fetchInstrutor();
  }, [alunoData]);

  /*--------------------*/

  //quantidade de dias no mes
  function getDaysInMonth(ano, mes) {
    return new Date(ano, mes, 0).getDate();
  }
  const totalDays = getDaysInMonth(ano, mes);

  //GET HOME STATS
  React.useEffect(() => {
    async function fetchStats() {
      const { url, options } = HOME_STATS_GET({ token });
      const { response, json } = await request(url, options);
      // console.log(json);
      setHomeStats(json);

      /*---FAZ O LOOP EM CADA ELEMNTO, SELECIONA SOMENTE O MES ATUAL E SOMA OS PONTOS----*/
      let ranking = [];
      json[0].forEach((item, i) => {
        let currentDates = Object.keys(item.calendario).filter(
          (item) =>
            //item.includes(mes + '-' + ano), //se for por semana
            currentWeek.some((e) => e.match(item)), // se for por semana
        );

        //retorna array com datas do mes atual
        const filtered = Object.keys(item.calendario)
          .filter((key) => currentDates.includes(key))
          .reduce((obj, key) => {
            obj[key] = item.calendario[key];
            return obj;
          }, {});

        //cria array com datas completas do mes atual
        let allDates = [];
        for (let i = 0; i <= totalDays; i++) {
          allDates.push(String(i).padStart(2, '0') + '-' + mes + '-' + ano);
        }

        //completa os arrays vazios com valores
        let emptyArr = allDates.reduce(
          (a, v) => ({ ...a, [v]: { pontos: 0, time: 0 } }),
          {},
        );

        //Faz o merge do array dos treinos com os vazios
        let mixArr = { ...emptyArr, ...filtered };

        ranking.push({
          nome: item.nome,
          sobrenome: item.sobrenome,
          author: item.author,
          photo: item.photo,
          pontos: Object.values(mixArr)
            .map((item) => {
              return item.pontos;
            })
            .reduce((a, b) => {
              return a + b;
            }),
        });

        //console.log(Object.values(filtered)[0].pontos);
      });

      //filtra somente os 5 primeiros colocados e coloca em ordem crescente
      let arr = ranking.sort((a, b) => b.pontos - a.pontos);
      let arr2 = [];
      //se a qtd for maior que 5 ele limita a 5
      const count = ranking.length < 5 ? ranking.length : 5;
      for (let index = 0; index < count; index++) {
        arr2.push(ranking[index]);
      }

      setUserRanking(arr2);
      //console.log(ranking.sort((a, b) => a.pontos + b.pontos));
      // console.log(arr2);
    }
    fetchStats();
  }, []);

  //Checa se a activação forçada estiver ligada
  if (alunoData.force_activate !== '1')
    if (alunoData.status === 'red')
      return (
        <div className="forbidden">
          <h1>
            Por favor,
            <br />
            entre em contato com
            <br />
            seu instrutor.
          </h1>
        </div>
      );

  //se hover dia da semana selecionado
  if (currentDay === '')
    return (
      <section className={styles.meuTreino}>
        <Header page="aluno" />
        <div className={styles.alunoTreino}>
          <Aniversario parabens={parabens} />
          {instrutorData && instrutorVideo !== '' && (
            <div className={styles.banner}>
              <Video
                codigo={instrutorData.video_aluno}
                size="240p"
                controls={true}
              />
            </div>
          )}
          {instrutorVideo === '' && (
            <div className={styles.banner}>
              <img src={instrutorBanner} alt="Banner" />
            </div>
          )}
          <div className={styles.exercicios}>
            {calendario[currentWeek[0]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Domingo',
                    treinos: treinoWeek.domingo,
                    day: currentWeek[0],
                  })
                }
              >
                <p>DOMINGO</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>DOMINGO</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}

            {calendario[currentWeek[1]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Segunda-Feira',
                    treinos: treinoWeek.segunda,
                    day: currentWeek[1],
                  })
                }
              >
                <p>SEGUNDA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>SEGUNDA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}

            {calendario[currentWeek[2]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Terça-Feira',
                    treinos: treinoWeek.terca,
                    day: currentWeek[2],
                  })
                }
              >
                <p>TERÇA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>TERÇA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}

            {calendario[currentWeek[3]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Quarta-Feira',
                    treinos: treinoWeek.quarta,
                    day: currentWeek[3],
                  })
                }
              >
                <p>QUARTA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>QUARTA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}

            {calendario[currentWeek[4]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Quinta-Feira',
                    treinos: treinoWeek.quinta,
                    day: currentWeek[4],
                  })
                }
              >
                <p>QUINTA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>QUINTA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}

            {calendario[currentWeek[5]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Sexta-Feira',
                    treinos: treinoWeek.sexta,
                    day: currentWeek[5],
                  })
                }
              >
                <p>SEXTA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>SEXTA-FEIRA</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}

            {calendario[currentWeek[6]] === undefined ? (
              <div
                className={styles.weekBt}
                onClick={() =>
                  setCurrentDay({
                    dia: 'Sábado',
                    treinos: treinoWeek.sabado,
                    day: currentWeek[6],
                  })
                }
              >
                <p>SÁBADO</p>
                <span className={`${styles.icon} ${styles.btCheckNull}`}></span>
              </div>
            ) : (
              <div className={styles.weekBtChecked}>
                <p>SÁBADO</p>
                <span className={`${styles.icon} ${styles.btCheck}`}></span>
              </div>
            )}
          </div>

          <div className={styles.exercicios3}>
            {homeStats && (
              <div className={styles.n_ex}>
                {`Estamos com 
                ${homeStats[1].exercicios}
                 exercícios cadastrados.`}
              </div>
            )}
          </div>

          <div className={styles.boxes4}>
            <h1 className={styles.subTitle}>Ranking da Semana</h1>
            {loading && <Loading type="dots" />}
            {userRanking &&
              userRanking.map((item, i) => (
                <BoxRanking
                  key={i}
                  foto={item.photo}
                  nome={item.nome}
                  pontos={item.pontos}
                  instrutor={item.author}
                  colocacao={i}
                  userType={alunoData.type}
                />
              ))}
          </div>
        </div>
      </section>
    );

  /*---------------------------------------------------------------------*/
  //se hover da da semana selecionado
  if (currentDay !== '')
    return (
      <section className={styles.meuTreino}>
        <Header page="aluno" />
        <div className={styles.alunoTreino}>
          <div className={styles.exercicios2}>
            <Weeks
              treino={currentDay}
              setCurrentDay={setCurrentDay}
              today={today}
              alunoData={alunoData}
              setCalendario={setCalendario}
            />
          </div>
        </div>
      </section>
    );
};

export default AlunoTreino;
