import React from 'react';
import * as XLSX from 'xlsx';
import useFetch from '../../Hooks/useFetch';
import { USER_GET_BY_ID } from '../../../api';
import { useLocation } from 'react-router-dom';
import styles from './Stats.module.css';
import StatsGraph from './StatsGraph';
import Loading from '../../Helper/Loading';
import BoxFinanceiro from '../Home/BoxFinanceiro';
import Button from '../../Forms/Button';

const Stats = () => {
  const token = window.localStorage.getItem('token');
  const path = window.location.pathname;
  const { request, loading } = useFetch();
  const [theID, setTheID] = React.useState(
    Number(path.replace(/\/$/, '').split('/').pop()),
  );

  const [calendar, setCalendar] = React.useState();

  const [graphPontos, setGraphPontos] = React.useState();
  const [graphTempo, setGraphTempo] = React.useState();
  const [trainingDays, setTrainingDays] = React.useState();

  const location = useLocation();
  const currentDate = new Date();
  const mes = String(currentDate.getMonth() + 1).padStart(2, '0');
  const ano = currentDate.getFullYear();

  const ma = mes + '-' + ano;
  const [mesExport, setMesExport] = React.useState(ma);

  //---FETCH DOS DADOS
  React.useEffect(() => {
    async function fetchDados() {
      const { url, options } = USER_GET_BY_ID({ token, theID });
      const { json } = await request(url, options);
      //console.log(json.calendario);
      setCalendar(json.calendario);
    }
    fetchDados();
  }, [location]);

  /*--------------------*/

  //quantidade de dias no mes
  function getDaysInMonth(ano, mes) {
    return new Date(ano, mes, 0).getDate();
  }
  const totalDays = getDaysInMonth(ano, mes);

  React.useEffect(() => {
    if (calendar) {
      //filtra somente datas do mes atual
      let currentDates = Object.keys(calendar).filter((item) =>
        item.includes(mes + '-' + ano),
      );

      //console.log(currentDates);

      //retorna array com datas do mes atual
      const filtered = Object.keys(calendar)
        .filter((key) => currentDates.includes(key))
        .reduce((obj, key) => {
          obj[key] = calendar[key];
          return obj;
        }, {});
      setTrainingDays(filtered);
      //console.log(filtered);

      //cria array com datas completas do mes atual
      let allDates = [];
      for (let i = 0; i <= totalDays; i++) {
        allDates.push(String(i).padStart(2, '0') + '-' + mes + '-' + ano);
      }

      //completa os arrays vazios com valores
      let emptyArr = allDates.reduce(
        (a, v) => ({ ...a, [v]: { pontos: 0, time: 0 } }),
        {},
      );

      //Faz o merge do array dos treinos com os vazios
      let mixArr = { ...emptyArr, ...filtered };

      //console.log(mixArr);

      //Criando objeto para o grafico de Pontos
      const graphData1 = Object.values(mixArr).map((item, index) => {
        return {
          x: index,
          y: Number(item.pontos),
        };
      });
      setGraphPontos(graphData1);
      //console.log(graphData1);

      //Criando objeto para o grafico de tempo
      const graphData2 = Object.values(mixArr).map((item, index) => {
        return {
          x: index,
          y: Number(item.time / 60000),
        };
      });
      setGraphTempo(graphData2);
      //  console.log(graphData2);
    }
  }, [calendar]);

  ///---EXPORT TO EXCEL BUTTON
  function exportXLS() {
    //Filtra o objeto somente com a data escolhina no select
    let dataTreinos = Object.keys(calendar).filter((item) =>
      item.includes(mesExport),
    );

    //retorna array com datas do mes escolhido e faz a conversão para minutos
    const dataTreinosFilter = Object.keys(calendar)
      .filter((key) => dataTreinos.includes(key))
      .reduce((obj, key) => {
        let item = calendar[key];
        //console.log(obj);
        obj[key] = {
          Dias: key,
          Pontos: item['pontos'],
          Tempo: Math.round(Number(item['time'] / 60000)) + 'min.',
        };
        return obj;
      }, {});

    console.log(dataTreinosFilter);

    const jsonToSheet = (json) => {
      const worksheet = XLSX.utils.json_to_sheet(json);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'folha1');
      return workbook;
    };

    const exportToExcel = (workbook, filename) => {
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    };

    const workbook = jsonToSheet(Object.values(dataTreinosFilter));

    //console.log(mesExport);

    exportToExcel(workbook, 'planilha_' + mesExport);
  }

  if (loading) return <Loading type="default" />;
  if (calendar)
    return (
      <div className={`${styles.graphs} animeLeft`}>
        <h1 className={styles.title1}>Estatísticas - {mes + '/' + ano}</h1>
        <div className={styles.stats}>
          <BoxFinanceiro
            titulo="Pontos"
            valor={
              graphPontos &&
              graphPontos.reduce((a, b) => {
                return a + b.y;
              }, 0)
            }
            icon="score"
          />
          <BoxFinanceiro
            titulo="Tempo treinado"
            valor={
              graphTempo &&
              Math.round(
                graphTempo.reduce((a, b) => {
                  return a + b.y;
                }, 0),
              ) + ' min.'
            }
            icon="timer"
          />
          <BoxFinanceiro
            titulo="Treinos realizados"
            valor={trainingDays && Object.keys(trainingDays).length}
            icon="exercicios"
          />
        </div>
        <p className={styles.subTitle}>Diário de Pontos</p>
        <div>
          <StatsGraph dados={graphPontos} color={'#eb6018'} />
        </div>
        <p className={styles.subTitle}>Diário de Tempo</p>
        <div>
          <StatsGraph dados={graphTempo} color={'#3d4ed7'} />
        </div>
        <div className={styles.downloadArea}>
          <select
            name="seldata"
            value={mesExport}
            className={styles.selData}
            onChange={(e) => setMesExport(e.target.value)}
          >
            <option value="01-2022">01-2022</option>
            <option value="02-2022">02-2022</option>
            <option value="03-2022">03-2022</option>
            <option value="04-2022">04-2022</option>
            <option value="05-2022">05-2022</option>
            <option value="06-2022">06-2022</option>
            <option value="07-2022">07-2022</option>
            <option value="08-2022">08-2022</option>
            <option value="09-2022">09-2022</option>
            <option value="10-2022">10-2022</option>
            <option value="11-2022">11-2022</option>
            <option value="12-2022">12-2022</option>
            <option value="01-2023">01-2023</option>
            <option value="02-2023">02-2023</option>
            <option value="03-2023">03-2023</option>
            <option value="04-2023">04-2023</option>
            <option value="05-2023">05-2023</option>
            <option value="06-2023">06-2023</option>
            <option value="07-2023">07-2023</option>
            <option value="08-2023">08-2023</option>
            <option value="09-2023">09-2023</option>
            <option value="10-2023">10-2023</option>
            <option value="11-2023">11-2023</option>
            <option value="12-2023">12-2023</option>
            <option value="01-2024">01-2024</option>
            <option value="02-2024">02-2024</option>
            <option value="03-2024">03-2024</option>
            <option value="04-2024">04-2024</option>
            <option value="05-2024">05-2024</option>
            <option value="06-2024">06-2024</option>
            <option value="07-2024">07-2024</option>
            <option value="08-2024">08-2024</option>
            <option value="09-2024">09-2024</option>
            <option value="10-2024">10-2024</option>
            <option value="11-2024">11-2024</option>
            <option value="12-2024">12-2024</option>
            <option value="01-2025">01-2025</option>
            <option value="02-2025">02-2025</option>
            <option value="03-2025">03-2025</option>
            <option value="04-2025">04-2025</option>
            <option value="05-2025">05-2025</option>
            <option value="06-2025">06-2025</option>
            <option value="07-2025">07-2025</option>
            <option value="08-2025">08-2025</option>
            <option value="09-2025">09-2025</option>
            <option value="10-2025">10-2025</option>
            <option value="11-2025">11-2025</option>
            <option value="12-2025">12-2025</option>
          </select>
          <Button onClick={exportXLS}>BAIXAR PLANILHA</Button>
        </div>
      </div>
    );
};

export default Stats;
