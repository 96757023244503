import React from 'react';

const types = {
  email: {
    regex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Preencha um email válido.',
  },
  password: {
    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
    message:
      'A senha deve ter no mínimo 8 caracteres, contendo uma letra maiuscula, uma minuscula e um número.',
  },
  date: {
    regex: /^(0[1-9]|1\d|2\d|3[01])\-(0[1-9]|1[0-2])\-(19|20)\d{2}$/,
    message: 'Preencha no formato correto: DD-MM-AAAA',
  },
  phone: {
    regex: /^\(?\d+\)?[-.\s]?\d+[-.\s]?\d+$/,
    message:
      'Utilize números sem espaço, com código do país e DDD: 5511888887777',
  },
};

const useForm = (type) => {
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(null);

  function validate(value) {
    if (type === false) return true;
    if (value.length === 0) {
      setError('Preencha um valor.');
      return false;
    } else if (types[type] && !types[type].regex.test(value)) {
      setError(types[type].message);
      return false;
    } else {
      setError(null);
      return true;
    }
  }

  function onChange({ target }) {
    if (error) validate(target.value);
    setValue(target.value);
  }

  function onClear() {
    console.log('clear');
    setValue('');
  }

  function initialValue(val) {
    setValue(val);
  }

  return {
    value,
    setValue,
    onChange,
    error,
    validate: () => validate(value),
    onBlur: () => validate(value),
    onClear: () => onClear(),
    initialValue: (val) => initialValue(val),
  };
};

export default useForm;
