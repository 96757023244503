import React from 'react';
import ReactPlayer from 'react-player';

const Video = ({ codigo, size, controls }) => {
  const url = `https://vimeo.com/${codigo}`;
  return (
    <ReactPlayer
      url={url}
      controls={controls}
      config={{
        vimeo: {
          playerOptions: {
            title: false,
            portrait: false,
            byline: false,
            api: 1,
            loop: 0,
            autoplay: 0,
            quality: '244p',
          },
        },
      }}
    />
  );
};

export default Video;
