import React from 'react';
import Footer from '../../Footer';
import Input from '../../Forms/Input';
import Header from '../../Header/Header';
import styles from './AddTreino.module.css';
import SpecifyBar from './SpecifyBar';
import SelectExercicios from './SelectExercicios';
import SelectGrupos from './SelectGrupos';
import ManageAreaTreino from './ManageAreaTreino';
import Button from '../../Forms/Button';
import { TREINO_POST } from '../../../api';
import useFetch from '../../Hooks/useFetch';
import useForm from '../../Hooks/useForm';
import { UserContext } from '../../Context/UserContext';
import Error from '../../Helper/Error';
import { ModalContext } from '../../Context/ModalContext';
import { AppContext } from '../../Context/AppContext';
import { useNavigate } from 'react-router-dom';

const AddTreino = () => {
  const token = window.localStorage.getItem('token');
  const { userID } = React.useContext(UserContext);
  const { loading, error, request } = useFetch();
  const [validate, setValidate] = React.useState(true);
  const nome = useForm();

  const [modal, setModal] = React.useState(false);

  const { setCounter } = React.useContext(AppContext);

  const navigate = useNavigate();

  const { itensExercicio, setItensExercicio } = React.useContext(ModalContext);

  //field de busca
  const [searchField, setSearchField] = React.useState(null);
  //exercicios selecionados
  const [selected, setSelected] = React.useState([]);

  //setAllParams((allParams) => [...allParams, initialValue]);

  async function treinoPost() {
    setValidate(true);
    if (nome.value !== '' && itensExercicio.length > 0) {
      console.log(itensExercicio);

      const body = {
        nome: nome.value,
        grade_exercicios: itensExercicio,
        author: userID,
      };

      const { url, options } = TREINO_POST({ body, token });
      const { response } = await request(url, options);
      if (response.ok) {
        setModal(true);
      }
    } else {
      setValidate(false);
    }
  }

  function handleReload() {
    window.localStorage.setItem('nptRascunho', JSON.stringify([]));
    //document.location.reload(true);
    setItensExercicio([]);
    nome.onClear();
    setModal(false);
    setCounter(JSON.parse(window.localStorage.getItem('nptRascunho')).length);
  }

  function handleRedirect() {
    setItensExercicio([]);
    window.localStorage.setItem('nptRascunho', JSON.stringify([]));
    setCounter(JSON.parse(window.localStorage.getItem('nptRascunho')).length);
    navigate('/listar-usuarios');
  }

  function handleRedirect2() {
    setItensExercicio([]);
    window.localStorage.setItem('nptRascunho', JSON.stringify([]));
    setCounter(JSON.parse(window.localStorage.getItem('nptRascunho')).length);
    navigate('/listar-exercicios');
  }

  return (
    <section className={styles.addTreino}>
      {modal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h1>TREINO SALVO COM SUCESSO!</h1>
            <span>
              <Button onClick={handleReload}>ADICIONAR OUTRO</Button>
            </span>
            <span>
              <Button onClick={handleRedirect}>LISTAR ALUNOS</Button>
            </span>
            <span>
              <Button onClick={handleRedirect2}>LISTAR EXERCÍCIOS</Button>
            </span>
          </div>
        </div>
      )}
      <Header page="addTreino" />
      <div className={styles.leftBar}>
        <h1 className={styles.title}>Criar Treino</h1>
        <Input
          label="Nome do treino"
          type="text"
          name="nome"
          color="#fff"
          {...nome}
        />
        <SpecifyBar
          selected={selected}
          setSelected={setSelected}
          itensExercicio={itensExercicio}
          setItensExercicio={setItensExercicio}
        />
        <SelectExercicios type="exercicios" setSearchField={setSearchField} />
        <SelectGrupos type="grupos" setSearchField={setSearchField} />
        <div className={styles.btSalvar}>
          {loading ? (
            <Button disabled>Salvando...</Button>
          ) : (
            <Button onClick={treinoPost}>Salvar Treino</Button>
          )}
          <Error message={error} />
          {!validate && <Error message={'Insira o nome e os exercícios'} />}
        </div>
      </div>
      <div className={styles.content}>
        <ManageAreaTreino
          searchField={searchField}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <Footer />
    </section>
  );
};

export default AddTreino;
