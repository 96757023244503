import React from 'react';
import styles from './Header.module.css';
import LogoArea from './LogoArea';
import MiddleArea from './MiddleArea';
import NotifyMessage from './NotifyMessage';
import RightArea from './RightArea';
import Modal from './../Modal/Modal';

const Header = ({ page }) => {
  return (
    <header className={styles.header}>
      <Modal />
      <LogoArea />
      <MiddleArea />
      <RightArea page={page} />
      <NotifyMessage />
    </header>
  );
};

export default Header;
