import React from 'react';

export const AppContext = React.createContext();

export const AppStorage = ({ children }) => {
  //Mensagem de notificação
  const [notify, setNotify] = React.useState({
    type: '',
    message: '',
  });

  //contador de rascunho
  const [counter, setCounter] = React.useState();
  React.useEffect(() => {
    //console.log(counter);
    if (window.localStorage.getItem('nptRascunho') !== null)
      setCounter(JSON.parse(window.localStorage.getItem('nptRascunho')).length);
  }, [counter]);

  return (
    <AppContext.Provider value={{ notify, setNotify, counter, setCounter }}>
      {children}
    </AppContext.Provider>
  );
};
