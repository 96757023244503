import React from 'react';
import styles from './SpecifyBar.module.css';
import Button from '../../Forms/Button';
import { ModalContext } from '../../Context/ModalContext';

const SpecifyBar = ({ selected, setSelected }) => {
  const { itensExercicio, setItensExercicio } = React.useContext(ModalContext);

  const [series, setSeries] = React.useState(0);
  const [repeticoes, setRepeticoes] = React.useState('');
  const [fazerpor, setFazerpor] = React.useState('');
  const [manterpor, setManterpor] = React.useState('');
  const [descanso, setDescanso] = React.useState('');
  const [carga, setCarga] = React.useState('');
  const [elastico, setElastico] = React.useState('');

  const fazerRef = React.useRef();
  const manterRef = React.useRef();
  const descansoRef = React.useRef();
  const elasticoRef = React.useRef();

  function clearInputs() {
    setSeries(0);
    setRepeticoes(0);
    setFazerpor(null);
    fazerRef.current.value = 0;
    setManterpor(null);
    manterRef.current.value = 0;
    setDescanso(null);
    descansoRef.current.value = 0;
    setCarga(0);
    setElastico(null);
    elasticoRef.current.value = 0;
    //console.log(itensExercicio);
  }

  //console.log(selected);

  function handleClick(e) {
    e.preventDefault();

    const updateArr = [
      { label: 'Séries', value: series, id: 1 },
      { label: 'Repetições', value: repeticoes, id: 2 },
      { label: 'Fazer Por', value: fazerpor, id: 3 },
      { label: 'Manter Por', value: manterpor, id: 4 },
      { label: 'Descanso', value: descanso, id: 5 },
      { label: 'Carga', value: carga, id: 6 },
      { label: 'Elástico', value: elastico, id: 7 },
    ];

    const editItens = selected.map((exID) => {
      return itensExercicio.filter((item) => item[0].dados.id === exID);
    });

    // console.log(itensExercicio);
    //console.log(editItens);

    const tempArr = editItens.map((item) => [
      [
        {
          dados: {
            ...item[0][0].dados,
          },
        },
        {
          atributos: [...updateArr],
        },
      ],
    ]);

    //setState(tempArr);
    //console.log(tempArr);

    // console.log(itensExercicio[0][0].dados.id);
    //console.log(state);

    //setItensExercicio(itensExercicio.map(u => u.id !== editedUser.id ? u : editedUser))
    let temp2 = [];
    selected.forEach((element, index) => {
      setItensExercicio((itensExercicio) => {
        return itensExercicio.map((item) =>
          item[0].dados.id !== element ? item : tempArr[index][0],
        );
      });
    });
  }

  return (
    <div className={styles.specifyBar}>
      <h2 className={styles.subTitle}>
        Selecione os exercícios e defina os parâmetros
      </h2>
      <div className={styles.combos}>
        <span>
          <label htmlFor="series" className={styles.label}>
            Séries
          </label>
          <input
            type="number"
            id="series"
            name="series"
            min="1"
            max="300"
            value={series}
            onChange={(e) => {
              setSeries(e.target.value);
            }}
          />
        </span>

        <span>
          <label htmlFor="repeticoes">Repetições</label>
          <input
            type="text"
            id="repeticoes"
            name="repeticoes"
            min="1"
            max="300"
            value={repeticoes}
            placeholder={'0+0+0'}
            onChange={(e) => {
              //Testa se são somente números e sinal de +
              if (/^[0-9 ()+]+$|^$/.test(e.target.value)) {
                if (!e.target.value.includes('++')) {
                  console.log('ok');
                  setRepeticoes(e.target.value);
                  //setFinal(eval(value));
                }
              }
            }}
          />
        </span>

        <span>
          <label htmlFor="fazerpor">Fazer por</label>
          <select
            id="fazerpor"
            ref={fazerRef}
            name="fazerpor"
            onChange={(e) => {
              setFazerpor(e.target.value);
            }}
          >
            <option value="0"></option>
            <option value="5s">5s</option>
            <option value="10s">10s</option>
            <option value="15s">15s</option>
            <option value="20s">20s</option>
            <option value="25s">25s</option>
            <option value="30s">30s</option>
            <option value="35s">35s</option>
            <option value="40s">40s</option>
            <option value="45s">45s</option>
            <option value="50s">50s</option>
            <option value="55s">55s</option>
            <option value="60s">60s</option>
            <option value="2min">2min</option>
            <option value="3min">3min</option>
            <option value="4min">4min</option>
            <option value="5min">5min</option>
            <option value="6min">6min</option>
            <option value="7min">7min</option>
            <option value="8min">8min</option>
            <option value="9min">9min</option>
            <option value="10min">10min</option>
            <option value="15min">15min</option>
            <option value="20min">20min</option>
            <option value="25min">25min</option>
            <option value="30min">30min</option>
            <option value="35min">35min</option>
            <option value="40min">40min</option>
            <option value="45min">45min</option>
            <option value="50min">50min</option>
            <option value="60min">60min</option>
          </select>
        </span>

        <span>
          <label htmlFor="manterpor">Manter por</label>
          <select
            id="manterpor"
            name="manterpor"
            ref={manterRef}
            onChange={(e) => {
              setManterpor(e.target.value);
            }}
          >
            <option value="0"></option>
            <option value="5s">5s</option>
            <option value="10s">10s</option>
            <option value="15s">15s</option>
            <option value="20s">20s</option>
            <option value="25s">25s</option>
            <option value="30s">30s</option>
            <option value="35s">35s</option>
            <option value="40s">40s</option>
            <option value="45s">45s</option>
            <option value="50s">50s</option>
            <option value="55s">55s</option>
            <option value="60s">60s</option>
            <option value="2min">2min</option>
            <option value="3min">3min</option>
            <option value="4min">4min</option>
            <option value="5min">5min</option>
            <option value="6min">6min</option>
            <option value="7min">7min</option>
            <option value="8min">8min</option>
            <option value="9min">9min</option>
            <option value="10min">10min</option>
            <option value="15min">15min</option>
            <option value="20min">20min</option>
            <option value="25min">25min</option>
            <option value="30min">30min</option>
          </select>
        </span>

        <span>
          <label htmlFor="descanso">Descanso</label>
          <select
            id="descanso"
            name="descanso"
            ref={descansoRef}
            onChange={(e) => {
              setDescanso(e.target.value);
            }}
          >
            <option value="0"></option>
            <option value="5s">5s</option>
            <option value="10s">10s</option>
            <option value="15s">15s</option>
            <option value="20s">20s</option>
            <option value="25s">25s</option>
            <option value="30s">30s</option>
            <option value="35s">35s</option>
            <option value="40s">40s</option>
            <option value="45s">45s</option>
            <option value="50s">50s</option>
            <option value="55s">55s</option>
            <option value="60s">60s</option>
            <option value="2min">2min</option>
            <option value="3min">3min</option>
            <option value="4min">4min</option>
            <option value="5min">5min</option>
            <option value="6min">6min</option>
            <option value="7min">7min</option>
            <option value="8min">8min</option>
            <option value="9min">9min</option>
            <option value="10min">10min</option>
            <option value="15min">15min</option>
            <option value="20min">20min</option>
            <option value="25min">25min</option>
            <option value="30min">30min</option>
          </select>
        </span>

        <span>
          <label htmlFor="carga">Carga</label>
          <input
            type="text"
            id="carga"
            name="carga"
            min="1"
            max="100"
            value={carga}
            onChange={(e) => {
              setCarga(e.target.value);
            }}
          />
        </span>

        <span className={styles.large}>
          <label htmlFor="elastico">Elástico</label>
          <select
            id="elastico"
            name="elastico"
            ref={elasticoRef}
            onChange={(e) => {
              setElastico(e.target.value);
            }}
          >
            <option value="0"></option>
            <option value="Extra-Leve">Extra-Leve</option>
            <option value="Leve">Leve</option>
            <option value="Médio">Médio</option>
            <option value="Forte">Forte</option>
            <option value="Extra-forte">Extra-Forte</option>
          </select>
        </span>
      </div>
      <span className={styles.btAplicar}>
        <Button onClick={handleClick}>Aplicar</Button>
        <div className={styles.btSelects}>
          <span
            className={`${styles.icon} ${styles.selectAllItens}`}
            onClick={() => {
              const sellAll = itensExercicio.map((item) => item[0].dados.id);
              //console.log(sellAll);
              setSelected(sellAll);
            }}
          ></span>
          <span
            className={`${styles.icon} ${styles.removeAllItens}`}
            onClick={() => {
              setSelected([]);
            }}
          ></span>
          <span
            className={`${styles.icon} ${styles.cleanSelects}`}
            onClick={() => {
              clearInputs();
            }}
          ></span>
        </div>
      </span>
    </div>
  );
};

export default SpecifyBar;
