import React from 'react';
import styles from './StopWatch.module.css';
import useWindowFocus from 'use-window-focus';

const StopWatch = ({
  startTime,
  setStartTime,
  running,
  setRunning,
  isStoped,
  setIsStoped,
  storage,
  setStorage,
  count,
  setCount,
}) => {
  const windowFocused = useWindowFocus();

  //Quando janela retoma o focus subtrai o valor do momento do play
  React.useEffect(() => {
    if (windowFocused) {
      if (!isStoped) onFocus(); // função de subtracao de tempo
    }
  }, [windowFocused]);

  //Função que soma segundos
  React.useEffect(() => {
    let id;
    if (running) {
      id = setInterval(() => setCount((oldCount) => oldCount + 1), 1000);
    } else if (!running) {
      clearInterval(id);
    }

    return () => {
      clearInterval(id);
    };
  }, [running]);

  //Inicia checando no storage se ja existe algum tempo realizado no dia e mostra na tela, se nao houver criaum em branco
  React.useEffect(() => {
    setCount(0);
    if (localStorage.getItem('current_time') !== null) {
      const dados = JSON.parse(localStorage.getItem('current_time'));
      const d_date = new Date();
      if (dados.day === d_date.getDate()) {
        setStorage(Object.assign({}, dados.time));
      } else {
        let d1 = new Date();
        let d2 = d1.getDate();
        let storage = { day: d2, time: [] };
        localStorage.setItem('current_time', JSON.stringify(storage));
      }
    } else {
      let d1 = new Date();
      let d2 = d1.getDate();
      let storage = { day: d2, time: [] };
      localStorage.setItem('current_time', JSON.stringify(storage));
    }
  }, []);

  //Quando ganha focus a janela o sistema pega a hora do click play e subtri para corrigir o COUNT
  function onFocus() {
    const date1 = new Date();
    const diffTime = Math.abs(startTime - date1);
    console.log(diffTime + ' milliseconds');
    setCount(diffTime / 1000);
  }

  //Quando inicia pega o minuto exato do momento
  function onStart() {
    setIsStoped(false);
    setRunning(true);
    setStartTime(new Date());
  }

  //quando stopa zera o count e o starttime, registra o novo tempo no storage
  function onStop() {
    setIsStoped(true);
    setRunning(false);
    setStartTime(0);
    setCount(0);

    //
    let prov = JSON.parse(localStorage.getItem('current_time'));
    let arr = prov.time;
    arr.push(count);
    let prov2 = { day: prov.day, time: arr };
    console.log(prov2);
    localStorage.setItem('current_time', JSON.stringify(prov2));
    setStorage(Object.assign({}, prov2.time));
  }

  React.useEffect(() => {
    // console.log(Object.values(storage).length);
    if (localStorage.getItem('current_time') !== null) {
    }
  }, [storage]);

  //Função para tratar os digitos no formato min.
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  //Função convert o count em milisegundos para horas, e retorna no formato 00:00:00
  function convertMsToHM(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = seconds >= 60 ? minutes + 1 : minutes;
    minutes = minutes % 60;
    hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
      seconds,
    )}`;
  }

  return (
    <>
      <div className={styles.timer}>
        <span className={`${styles.cron}`}></span>
        <p>
          <span style={{ display: 'block' }}>
            {convertMsToHM(count * 1000)}
          </span>
        </p>
        {!running && (
          <span
            className={`${styles.icon} ${styles.play}`}
            onClick={onStart}
          ></span>
        )}
        {running && (
          <span
            className={`${styles.icon} ${styles.pause}`}
            onClick={onStop}
          ></span>
        )}
      </div>
      {Object.values(storage).length > 0 && (
        <div className={styles.listTime}>
          <p>
            <b>Parciais:</b>
          </p>
          {Object.values(storage).map((item, i) => (
            <p key={i}>{convertMsToHM(Number(item * 1000))}</p>
          ))}
        </div>
      )}
    </>
  );
};

export default StopWatch;
