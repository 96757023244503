import React from 'react';
import Footer from '../../Footer';
import Input from '../../Forms/Input';
import Header from '../../Header/Header';
import styles from './EditarGrupo.module.css';
import SpecifyBar from './../AdicionarTreino/SpecifyBar';
import SelectExercicios from './../AdicionarTreino/SelectExercicios';
import ManageAreaEditarTreino from '../EditarTreino/ManageAreaEditarTreino';
import Button from '../../Forms/Button';
import { GRUPO_PUT } from '../../../api';
import useFetch from '../../Hooks/useFetch';
import { UserContext } from '../../Context/UserContext';
import Error from '../../Helper/Error';
import { ModalContext } from '../../Context/ModalContext';
import { AppContext } from '../../Context/AppContext';

const EditarTreino = () => {
  const token = window.localStorage.getItem('token');
  const { userID } = React.useContext(UserContext);
  const { loading, error, request } = useFetch();
  const [validate, setValidate] = React.useState(true);
  const { setNotify } = React.useContext(AppContext);

  const { itensExercicio, setItensExercicio } = React.useContext(ModalContext);
  //field de busca
  const [searchField, setSearchField] = React.useState(null);
  //exercicios selecionados
  const [selected, setSelected] = React.useState([]);
  //nome do treino
  const [nomeTreino, setNomeTreino] = React.useState('');

  const path = window.location.pathname;
  const exID = Number(path.replace(/\/$/, '').split('/').pop());

  //setAllParams((allParams) => [...allParams, initialValue]);

  async function treinoPut() {
    setValidate(true);
    if (nomeTreino !== '' && itensExercicio.length > 0) {
      const body = {
        nome: nomeTreino,
        grade_exercicios: itensExercicio,
        author: userID,
      };

      const { url, options } = GRUPO_PUT({ body, token, exID });
      const { response } = await request(url, options);
      if (response.ok) {
        setNotify({
          type: 'success',
          message: 'O Grupo foi atualizado.',
        });
      } else {
        setNotify({
          type: 'error',
          message: 'Houve um erro.',
        });
      }
    } else {
      setValidate(false);
    }
  }

  React.useEffect(() => {
    if (nomeTreino === '') {
      setNomeTreino('Aguarde, carreganto...');
    }
  }, []);

  return (
    <section className={styles.editarGrupo}>
      <Header page="editarGrupo" />
      <div className={styles.leftBar}>
        <h1 className={styles.title}>Editar Grupo</h1>
        <Input
          label="Nome do Treino"
          onChange={(e) => setNomeTreino(e.target.value)}
          value={nomeTreino}
          type="text"
          name="nome"
          color="#fff"
        />
        <SpecifyBar
          selected={selected}
          setSelected={setSelected}
          itensExercicio={itensExercicio}
          setItensExercicio={setItensExercicio}
        />
        <SelectExercicios type="exercicios" setSearchField={setSearchField} />
        <div className={styles.btSalvar}>
          {loading ? (
            <Button disabled>Atualizando...</Button>
          ) : (
            <Button onClick={treinoPut}>Atualizar Grupo</Button>
          )}
          <Error message={error} />
          {!validate && <Error message={'Insira o nome e os exercícios'} />}
        </div>
      </div>
      <div className={styles.content}>
        <ManageAreaEditarTreino
          searchField={searchField}
          selected={selected}
          setSelected={setSelected}
          exID={exID}
          setNomeTreino={setNomeTreino}
        />
      </div>
      <Footer />
    </section>
  );
};

export default EditarTreino;
