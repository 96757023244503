import React from 'react';
import styles from './Ranking.module.css';
import Header from '../../Header/Header';
import useFetch from '../../Hooks/useFetch';
import { HOME_STATS_GET } from '../../../api';
import BoxRanking from '../Home/BoxRanking';
import Loading from '../../Helper/Loading';

const Ranking = () => {
  const token = window.localStorage.getItem('token');
  const { loading, request } = useFetch();

  const currentDate = new Date();
  const mes = String(currentDate.getMonth() + 1).padStart(2, '0');
  const ano = currentDate.getFullYear();

  let norepeat = false;

  const [userRanking, setUserRanking] = React.useState([]);
  let vv = [];

  /*------------------------PEGA TODAS AS SEMANAS DO ANO--------------------------*/

  let curYear = new Date().getFullYear();
  //let curYear = 2024;
  //let curr = new Date('2022-01-01'); // get current date

  //Pega o primeiro e o último dia do ano atual e capta todos os domingos
  let mondays = [];
  let x = new Date(curYear + '-01-01');
  let y = new Date(curYear + '-12-31');
  var j = 1;
  var count = 0;
  //getting the all mondays in a financial year
  for (var i = 0; x < y; i += j) {
    if (x.getDay() === 1) {
      mondays.push(
        x.getFullYear() +
          '-' +
          ('0' + (x.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + x.getDate()).slice(-2),
      );
      x = new Date(x.getTime() + 7 * 24 * 60 * 60 * 1000);
      j = 7;
      count++;
    } else {
      j = 1;
      x = new Date(x.getTime() + 24 * 60 * 60 * 1000);
    }
  }

  //console.log(mondays);

  let weeks = [];

  //retorna os dias entre as datas
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());
    const dates = [];
    while (date <= endDate) {
      dates.push(new Date(date).toISOString().slice(0, 10));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  //Pega o primeiro e o último dia da semana de cada item do array e envia para função 'getDatesInRange'
  for (let i = 0; i < mondays.length; i++) {
    //console.log(mondays[i]);
    let fixDate = mondays[i];
    let curr = new Date(fixDate); // get current date
    // console.log(curr);

    let first = curr.getDate() - (curr.getDay() + 1); // First day is the day of the month - the day of the week
    let fix = false;
    if (first === 0) {
      fix = true; //Corrigi se cair o dia primeiro no primeiro dia da semana, estava dando problema
    }
    let last = first + 6; // last day is the first day + 6

    let firstday = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    let lastday = new Date(curr.setDate(last)).toISOString().slice(0, 10);

    if (fix) {
      //Corrigi se cair o dia primeiro no primeiro dia da semana, estava dando problema
      let datinha = new Date(lastday);
      datinha.setMonth(datinha.getMonth() + 1); // aumenta um mes
      weeks.push(getDatesInRange(new Date(firstday), new Date(datinha)));
    } else {
      weeks.push(getDatesInRange(new Date(firstday), new Date(lastday)));
    }
  }

  // console.log(weeks);

  /*------------------------------*/

  //GET HOME STATS
  React.useEffect(() => {
    async function fetchStats() {
      if (!norepeat) {
        const { url, options } = HOME_STATS_GET({ token });
        const { json } = await request(url, options);
        //console.log(json);

        /*---FAZ O LOOP EM CADA ELEMNTO, SELECIONA SOMENTE O MES ATUAL E SOMA OS PONTOS----*/
        let ranking = [];

        //console.log(weeks.length);

        weeks.forEach((element, j) => {
          //console.log(element);
          json[0].forEach((item) => {
            let currentDates = Object.keys(item.calendario).filter(
              (item) =>
                //console.log(item.split(' ')[0].split('-').reverse().join('-'));

                //item.includes(mes + '-' + ano), // se o rankig for por mês
                //pega as datas da semana atual e verifica se existe treino
                //element.some((e) => e.match(item)), // se for por semana
                element.some((e) =>
                  e.match(item.split(' ')[0].split('-').reverse().join('-')),
                ), // se for por semana
            );

            //console.log(item);

            //console.log(currentDates);

            //retorna array com datas do mes atual
            const filtered = Object.keys(item.calendario)
              .filter((key) => currentDates.includes(key))
              .reduce((obj, key) => {
                obj[key] = item.calendario[key];
                return obj;
              }, {});

            //quantidade de dias no mes
            function getDaysInMonth(ano, mes) {
              return new Date(ano, mes, 0).getDate();
            }
            const totalDays = getDaysInMonth(ano, mes);

            //cria array com datas completas do mes atual
            let allDates = [];
            for (let i = 0; i <= totalDays; i++) {
              allDates.push(String(i).padStart(2, '0') + '-' + mes + '-' + ano);
            }

            //completa os arrays vazios com valores
            let emptyArr = allDates.reduce(
              (a, v) => ({ ...a, [v]: { pontos: 0, time: 0 } }),
              {},
            );

            //Faz o merge do array dos treinos com os vazios
            let mixArr = { ...emptyArr, ...filtered };

            ranking.push({
              nome: item.nome,
              sobrenome: item.sobrenome,
              pontos: Object.values(mixArr)
                .map((item) => {
                  return item.pontos;
                })
                .reduce((a, b) => {
                  return a + b;
                }),
            });

            //console.log(Object.values(filtered)[0].pontos);
          });

          //filtra somente os 5 primeiros colocados e coloca em ordem crescente
          let arr = ranking.sort((a, b) => b.pontos - a.pontos);
          let arr2 = [];
          //se a qtd for maior que 5 ele limita a 5
          const count = ranking.length < 3 ? ranking.length : 3;
          for (let index = 0; index < count; index++) {
            arr2.push(ranking[index]);
          }

          vv.push(arr2);
          //console.log(vv);
          //console.log(j);

          setUserRanking(vv);
          //console.log(ranking.sort((a, b) => a.pontos + b.pontos));
          //console.log(arr2);
          ranking = [];
        });
      }
    }

    fetchStats();
    norepeat = true;
  }, []);

  return (
    <section className={styles.ranking}>
      <Header page="ranking" />
      <div className={styles.leftBar}>
        <h1 className={styles.title}>Ranking Semanal</h1>
        <h1 className={styles.title}>{ano}</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.content_ranks}>
          {loading && <Loading type="default" />}
          {userRanking &&
            userRanking.map((item, i) => (
              <div key={i} className={styles.rankItem}>
                <p className={styles.week}>Semana {i + 1}</p>
                {item[0].pontos !== 0 && (
                  <>
                    <p>
                      <b>1- </b>
                      {item[0].nome} {item[0].sobrenome} :{' '}
                      <b>{item[0].pontos}</b>
                    </p>
                    <p>
                      <b>2- </b>
                      {item[1].nome} {item[1].sobrenome} :{' '}
                      <b>{item[1].pontos}</b>
                    </p>
                    <p>
                      <b>3- </b>
                      {item[2].nome} {item[2].sobrenome} :{' '}
                      <b>{item[2].pontos}</b>
                    </p>
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Ranking;
