import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useForm from '../../Hooks/useForm';
import useFetch from '../../Hooks/useFetch';
import Button from '../../Forms/Button';
import Input from '../../Forms/Input';
import Error from '../../Helper/Error';
import styles from './LostForm.module.css';
import { PASSWORD_RESET } from '../../../api';

const LostForm = () => {
  const password = useForm();
  const { request, error, loading } = useFetch();
  const [login, setLogin] = React.useState('');
  const [key, setKey] = React.useState('');
  const navigate = useNavigate();

  //Função do botão click
  async function handleSubmit(event) {
    event.preventDefault();

    if (password.validate()) {
      const { url, options } = PASSWORD_RESET({
        login,
        key,
        password: password.value,
      });
      const { response } = await request(url, options);
      if (response.ok) {
        navigate('/login');
      }
    }
  }

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const key = params.get('key');
    const login = params.get('login');
    if (key) setKey(key);
    if (login) setLogin(login);
  }, []);

  return (
    <section className="animeLeft">
      <div className={styles.logo}></div>
      <h1 className="title">Resetar sua senha</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Input
          label="Nova Senha"
          type="password"
          name="password"
          {...password}
        />
        {loading ? (
          <Button disabled>Alterando...</Button>
        ) : (
          <Button>Alterar</Button>
        )}
        <Error message={error} />
        <p style={{ marginTop: '20px' }}></p>
      </form>
      <Link className={styles.perdeu} to="/login">
        Voltar
      </Link>
    </section>
  );
};

export default LostForm;
