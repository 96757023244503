import React from 'react';
import styles from './BoxFinanceiro.module.css';

const BoxFinanceiro = ({ titulo, valor, icon }) => {
  return (
    <div className={styles.box}>
      <div className={styles.icon + ' ' + styles[icon]}></div>
      <div className={styles.infos}>
        <p className={styles.titulo}>{titulo}</p>
        <h1 className={styles.valor}>{valor}</h1>
      </div>
    </div>
  );
};

export default BoxFinanceiro;
