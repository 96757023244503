import React from 'react';
import styles from './ListarTreinos.module.css';
import Header from './../../Header/Header';
import Footer from './../../Footer';
import ListagemAreaTreinos from './ListagemAreaTreinos';

const ListarTreinos = () => {
  const [filtro, setFiltro] = React.useState('');
  const [search, setSearch] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    setFiltro(search);
  };

  return (
    <section className={styles.listarTreinos}>
      <Header page="listarTreinos" />
      <div className={styles.leftBar}>
        <h1 className={styles.title}>Meus Treinos Salvos</h1>
        <form
          onSubmit={handleSubmit}
          className={`${styles.form} ${styles.large}`}
        >
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button type="submit">FILTRAR</button>
        </form>
      </div>
      <div className={styles.content}>
        <ListagemAreaTreinos filtro={filtro} />
      </div>
      <Footer />
    </section>
  );
};

export default ListarTreinos;
