import React from 'react';
import styles from './VideoItem.module.css';
import Video from './Video';
import { AppContext } from '../../Context/AppContext';
import { UserContext } from '../../Context/UserContext';
import { useNavigate } from 'react-router-dom';
import { EXERCICIO_DELETE } from '../../../api';
import useFetch from '../../Hooks/useFetch';

const VideoItem = ({ item, setListaExercicios }) => {
  const { setNotify, setCounter } = React.useContext(AppContext);
  const { userType } = React.useContext(UserContext);
  const navigate = useNavigate();
  const { request } = useFetch();
  const token = window.localStorage.getItem('token');

  function handleRedirect(e) {
    e.preventDefault();
    navigate('/editar-exercicio/' + item.id);
  }

  async function handleDelete(e) {
    e.preventDefault();
    const exID = item.id;
    if (window.confirm('Confirma a exclusão deste exercício?')) {
      const { url, options } = EXERCICIO_DELETE({ exID, token });
      const { response } = await request(url, options);
      if (response.ok) {
        setNotify({
          type: 'success',
          message: 'Exercício Excluído.',
        });
        setListaExercicios((current) =>
          current.filter((itensExercicios) => {
            // 👇️ remove object that has id equal to 2
            return itensExercicios.id !== exID;
          }),
        );
      } else {
        setNotify({ type: 'error', message: 'Houve um Erro.' });
      }
    }
  }

  return (
    <li className={styles.item}>
      <div className={styles.image}>
        <Video codigo={item.codigo_video} size="240p" controls={true} />
        <div className={styles.overColor}></div>
        <span
          className={`${styles.icon} ${styles.addRascunho}`}
          onClick={() => {
            let storage = [];
            storage =
              JSON.parse(window.localStorage.getItem('nptRascunho')) || [];
            if (storage.includes(item.id)) {
              setNotify({
                type: 'success',
                message: 'Exercício já adicionado',
              });
            } else {
              storage.push(item.id);
              localStorage.setItem('nptRascunho', JSON.stringify(storage));
              setNotify({ type: 'success', message: 'Adicionando Exercício' });
              setCounter(
                JSON.parse(window.localStorage.getItem('nptRascunho')).length,
              );
            }
          }}
        ></span>
        {userType === 'master' && (
          <span
            className={`${styles.icon} ${styles.editExercicio}`}
            onClick={handleRedirect}
          ></span>
        )}
        {userType === 'master' && (
          <span
            className={`${styles.icon} ${styles.removeExercicio}`}
            onClick={handleDelete}
          ></span>
        )}
      </div>
      <div className={styles.infos}>
        <h3 className={styles.titulo}>{item.nome}</h3>
        <p className={styles.objetivos}>{item.objetivos}</p>
      </div>
    </li>
  );
};

export default VideoItem;
