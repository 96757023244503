import React from 'react';
import Button from '../../Forms/Button';
import SelectTax from './SelectTax';
import styles from './TermsBar.module.css';

const TermsBar = ({ setTerm, term, setQ, q }) => {
  const [search, setSearch] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setTerm('');
    setQ(search);
  };

  const handleChange = (e) => {
    e.preventDefault();
    //setTerm('');
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    setSearch('');
  }, [term]);

  return (
    <div className={styles.termsBar}>
      <SelectTax
        slug={'especialidades'}
        nome={'Modalidades'}
        setTerm={setTerm}
        setQ={setQ}
      />
      <SelectTax
        slug={'objetivos'}
        nome={'Objetivos'}
        setTerm={setTerm}
        setQ={setQ}
      />
      <SelectTax
        slug={'dificuldades'}
        nome={'Dificuldades'}
        setTerm={setTerm}
        setQ={setQ}
      />
      <SelectTax
        slug={'esforco'}
        nome={'Esforço'}
        setTerm={setTerm}
        setQ={setQ}
      />
      <SelectTax
        slug={'equipamentos'}
        nome={'Equipamentos'}
        setTerm={setTerm}
        setQ={setQ}
      />
      <SelectTax
        slug={'grupamento'}
        nome={'Grupamento Muscular'}
        setTerm={setTerm}
        setQ={setQ}
      />
      <form
        onSubmit={handleSubmit}
        className={`${styles.form} ${styles.large}`}
      >
        <input type="text" value={search} onChange={handleChange} />
        <button type="submit">FILTRAR</button>
      </form>
    </div>
  );
};

export default TermsBar;
