import React from 'react';
import styles from './EditarTreinoAluno.module.css';
import { TREINOS_GET, SEND_MAIL, USER_TREINO_PUT } from '../../../api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Helper/Loading';
import Select from 'react-select';
import TextArea from '../../Forms/TextArea';
import { AppContext } from '../../Context/AppContext';

//style React Select
const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: '1px solid #eee',
    fontSize: '1rem',
    padding: '.6rem',
    borderRadius: '.4rem',
    margin: '15px 0',
    '&:hover': {
      border: '1px solid #eb6018',
    },
  }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
    };
  },
};

const EditarTreinoAluno = ({ alunoID, alunoData }) => {
  const token = window.localStorage.getItem('token');
  const { data, loading, error, request } = useFetch();
  const [filteredArray, setFilteredArray] = React.useState(null);
  const [obs, setObs] = React.useState('');
  const { setNotify } = React.useContext(AppContext);
  const [sendBT, setSendBT] = React.useState(false);

  const [segunda, setSegunda] = React.useState([]);
  const [terca, setTerca] = React.useState([]);
  const [quarta, setQuarta] = React.useState([]);
  const [quinta, setQuinta] = React.useState([]);
  const [sexta, setSexta] = React.useState([]);
  const [sabado, setSabado] = React.useState([]);
  const [domingo, setDomingo] = React.useState([]);

  const page = 1;
  const nItens = -1;
  const q = '';

  //---BOTÃO DE ENVIO WHATSAPP
  function handleSave(e) {
    e.preventDefault();
    setSendBT(true);

    const treino = {
      mensagem: obs,
      segunda: segunda,
      terca: terca,
      quarta: quarta,
      quinta: quinta,
      sexta: sexta,
      sabado: sabado,
      domingo: domingo,
    };

    const body = {
      author: alunoData.author,
      treino_atual: treino,
    };

    //console.log(treino);
    console.log(body, alunoID);
    treinoAtualPut(body);
  }

  async function treinoAtualPut(body) {
    const { url, options } = USER_TREINO_PUT({ token, alunoID, body });
    const { response, json } = await request(url, options);
    console.log(json);
    if (response.ok) {
      setNotify({
        type: 'success',
        message: 'Treino alterado',
      });
    } else {
      setNotify({
        type: 'error',
        message: 'Houve um erro.',
      });
    }
  }

  function handleWhats(e) {
    e.preventDefault();
    window.open(`https://api.whatsapp.com/send?phone=${alunoData.whatsapp}`);
    console.log('whats');
  }

  //---BOTÃO DE ENVIO DE EMAIL
  async function handleMail(e) {
    e.preventDefault();
    const type = 'treino_alterado';

    const { url, options } = SEND_MAIL({ token, alunoID, type });
    const { response } = await request(url, options);
    if (response.ok) {
      setNotify({
        type: 'success',
        message: 'Email enviado!',
      });
    } else {
      setNotify({
        type: 'error',
        message: 'Houve um erro no envio.',
      });
    }
  }

  //---FETCH DOS SELECTS COM OS TREINOS
  React.useEffect(() => {
    async function fetchTreinos() {
      const { url, options } = TREINOS_GET({ page, nItens, q, token });
      const { json } = await request(url, options);
      // console.log(json);
      //filtra array somente com campo id e label
      setFilteredArray(
        json.map((item) => ({
          value: item.id,
          label: item.nome,
        })),
      );
    }
    fetchTreinos();
  }, []);

  //GET USER TREINO ATUAL
  React.useEffect(() => {
    if (alunoData) {
      //console.log(alunoData.treino_atual);
      setObs(alunoData.treino_atual.mensagem);
      setSegunda(alunoData.treino_atual.segunda);
      setTerca(alunoData.treino_atual.terca);
      setQuarta(alunoData.treino_atual.quarta);
      setQuinta(alunoData.treino_atual.quinta);
      setSexta(alunoData.treino_atual.sexta);
      setSabado(alunoData.treino_atual.sabado);
      setDomingo(alunoData.treino_atual.domingo);
    }
  }, [alunoData]);

  if (loading) return <Loading type="default" />;
  if (data)
    return (
      <div className={`${styles.editarTreino} animeLeft`}>
        <h1 className={styles.title}>Treino Aluno</h1>
        <TextArea obs={obs} setObs={setObs} label={'Mensagem para o aluno'} />
        <div className={styles.contentSemanas}>
          <div className={styles.semana}>
            <p className={styles.nameWeek}>Segunda-Feira</p>
            <Select
              options={filteredArray && filteredArray}
              placeholder={'Insira o Treino'}
              value={segunda}
              onChange={(...args) => setSegunda(...args)}
              isSearchable={true}
              isMulti
              styles={colourStyles}
              isOptionDisabled={() => segunda.length >= 1}
            />
          </div>
          <div className={styles.semana}>
            <p className={styles.nameWeek}>Terça-Feira</p>
            <Select
              options={filteredArray && filteredArray}
              placeholder={'Insira o Treino'}
              value={terca}
              onChange={(...args) => setTerca(...args)}
              isSearchable={true}
              isMulti
              styles={colourStyles}
              isOptionDisabled={() => terca.length >= 1}
            />
          </div>
          <div className={styles.semana}>
            <p className={styles.nameWeek}>Quarta-Feira</p>
            <Select
              options={filteredArray && filteredArray}
              placeholder={'Insira o Treino'}
              value={quarta}
              onChange={(...args) => setQuarta(...args)}
              isSearchable={true}
              isMulti
              styles={colourStyles}
              isOptionDisabled={() => quarta.length >= 1}
            />
          </div>
          <div className={styles.semana}>
            <p className={styles.nameWeek}>Quinta-Feira</p>
            <Select
              options={filteredArray && filteredArray}
              placeholder={'Insira o Treino'}
              value={quinta}
              onChange={(...args) => setQuinta(...args)}
              isSearchable={true}
              isMulti
              styles={colourStyles}
              isOptionDisabled={() => quinta.length >= 1}
            />
          </div>
          <div className={styles.semana}>
            <p className={styles.nameWeek}>Sexta-Feira</p>
            <Select
              options={filteredArray && filteredArray}
              placeholder={'Insira o Treino'}
              value={sexta}
              onChange={(...args) => setSexta(...args)}
              isSearchable={true}
              isMulti
              styles={colourStyles}
              isOptionDisabled={() => sexta.length >= 1}
            />
          </div>
          <div className={styles.semana}>
            <p className={styles.nameWeek}>Sábado</p>
            <Select
              options={filteredArray && filteredArray}
              placeholder={'Insira o Treino'}
              value={sabado}
              onChange={(...args) => setSabado(...args)}
              isSearchable={true}
              isMulti
              styles={colourStyles}
              isOptionDisabled={() => sabado.length >= 1}
            />
          </div>
          <div className={styles.semana}>
            <p className={styles.nameWeek}>Domingo</p>
            <Select
              options={filteredArray && filteredArray}
              placeholder={'Insira o Treino'}
              value={domingo}
              onChange={(...args) => setDomingo(...args)}
              isSearchable={true}
              isMulti
              styles={colourStyles}
              isOptionDisabled={() => domingo.length >= 1}
            />
          </div>
          <div className={styles.btSalvar}>
            <button onClick={handleSave}>SALVAR TREINO</button>
            {sendBT && (
              <button onClick={handleWhats} className={styles.whats}>
                ENVIAR
              </button>
            )}
            {sendBT && (
              <button onClick={handleMail} className={styles.email}>
                ENVIAR
              </button>
            )}
          </div>
        </div>
      </div>
    );
};

export default EditarTreinoAluno;
