import React from 'react';
import { Link } from 'react-router-dom';
import useForm from '../../Hooks/useForm';
import { UserContext } from '../../Context/UserContext';
import Button from '../../Forms/Button';
import Input from '../../Forms/Input';
import Error from '../../Helper/Error';
import styles from './LoginForm.module.css';

const LoginForm = () => {
  const username = useForm();
  const password = useForm();

  const { userLogin, error, loading } = React.useContext(UserContext);

  const [passType, setPassType] = React.useState('password');

  function ChangeEye() {
    if (passType === 'text') {
      setPassType('password');
    } else {
      setPassType('text');
    }
  }

  //Função do botão click
  async function handleSubmit(event) {
    event.preventDefault();

    //Valida infos dentro do useForm
    if (username.validate() && password.validate()) {
      userLogin(username.value, password.value);
    }
  }

  return (
    <section className="animeLeft">
      <div className={styles.logo}></div>
      <h1 className="title">Login</h1>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Input label="Usuário" type="text" name="username" {...username} />
        <Input label="Senha" type={passType} name="password" {...password} />
        <div
          className={passType === 'text' ? styles.eyePassOff : styles.eyePass}
          onClick={ChangeEye}
        ></div>
        <div className={styles.remember}>
          <input type="checkbox" name="lembrar" id="lembrar" />
          <label htmlFor="lembrar">Lembrar de mim</label>
        </div>
        <span style={{ display: 'flex' }}>
          {loading ? (
            <Button disabled>Carregando...</Button>
          ) : (
            <Button>Entrar</Button>
          )}
          <Error message={error} />
        </span>
      </form>
      <div className={styles.links}>
        <Link className={styles.perdeu} to="/login/perdeu">
          Perdeu / Trocar Senha?
        </Link>
        <a
          className={styles.perdeu}
          href="https://cadastro.nascidosparatreinar.com/"
        >
          Cadastre-se
        </a>
      </div>
    </section>
  );
};

export default LoginForm;
