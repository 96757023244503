import React from 'react';
import styles from './Loading.module.css';

const Loading = ({ type }) => {
  //se for padrão
  if (type === 'default') {
    return (
      <div className={styles.loading}>
        <span className={styles.default}></span>
      </div>
    );
  }
  //se for dots
  if (type === 'dots') {
    return (
      <div className={styles.loading}>
        <span className={styles.dots}></span>
      </div>
    );
  }
};

export default Loading;
