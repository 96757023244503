import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../Context/UserContext';

const ProtectedInfo = ({ children, type }) => {
  const { login, userType } = React.useContext(UserContext);
  //console.log(userType);
  //console.log(type);
  //console.log(login);
  // console.log(type.includes(userType));

  if (login && type.includes(userType)) {
    return children;
  } else if (login && !type.includes(userType)) {
    return (
      <div className="forbidden">
        <h1>
          Você não tem permissão
          <br />
          para acessar esse conteúdo.
        </h1>
      </div>
    );
  } else if (!login) {
    return <Navigate to="/login" />;
  }
};

export default ProtectedInfo;
