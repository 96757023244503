import React from 'react';
import styles from './SelectTax.module.css';
import { TERMS_GET } from '../../../api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Helper/Loading';

const SelectTax = ({ slug, nome, setTerm, setQ }) => {
  const token = window.localStorage.getItem('token');
  //console.log(nome);
  const { data, loading, error, request } = useFetch();

  React.useEffect(() => {
    async function fetchTerms() {
      const { url, options } = TERMS_GET({ slug, token });
      const { response, json } = await request(url, options);
    }
    fetchTerms();
  }, [request, slug]);

  if (loading) return <Loading type="dots" />;
  if (data)
    return (
      <select
        className={styles.select}
        value={''}
        onChange={({ target }) => {
          setTerm(target.value);
          setQ('');
        }}
      >
        <option value="" disabled defaultValue>
          {' '}
          {nome}
        </option>

        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    );
  else return null;
};

export default SelectTax;
