import React from 'react';
import styles from './ManageAdv.module.css';
import Header from '../../Header/Header';
import Button from '../../Forms/Button';
import Error from '../../Helper/Error';
import useFetch from '../../Hooks/useFetch';
import { AppContext } from '../../Context/AppContext';
import { UserContext } from '../../Context/UserContext';
import { MANAGER_PUT, MANAGER_GET } from '../../../api';

const ManageAdv = () => {
  const token = window.localStorage.getItem('token');
  const { loading, request } = useFetch();
  const { userID, userType } = React.useContext(UserContext);
  const { setNotify } = React.useContext(AppContext);

  const [videoAluno, setVideoAluno] = React.useState();
  const [videoInstrutor, setVideoInstrutor] = React.useState();

  const [preview1, setPreview1] = React.useState();
  const [file1, setFile1] = React.useState();
  const [loadingPic1, setLoadingPic1] = React.useState(false);

  const [preview2, setPreview2] = React.useState();
  const [file2, setFile2] = React.useState();
  const [loadingPic2, setLoadingPic2] = React.useState(false);

  const [erro, setErro] = React.useState('');

  //---BOTÃO DE ENVIO DE EMAIL
  function handleClick(e) {
    e.preventDefault();

    const body = {
      videoAluno: videoAluno,
      videoInstrutor: videoInstrutor,
    };

    async function fetchInput() {
      const { url, options } = MANAGER_PUT({ token, body });
      const { response } = await request(url, options);
      //console.log(response);

      if (response.ok) {
        setNotify({
          type: 'success',
          message: 'Vídeos alterados.',
        });
      } else {
        setNotify({
          type: 'error',
          message: 'Houve um erro.',
        });
      }
    }
    fetchInput();
  }

  /*-------BANNER ALUNO------*/
  function handleChange1(e) {
    setErro('');
    if (e.target.files[0]) {
      if (e.target.files[0].size > 5000000) {
        setErro('A imagem deve ter no máx 5mb');
      } else {
        setFile1(e.target.files[0]);
        setPreview1(URL.createObjectURL(e.target.files[0]));
      }
    }
  }

  const handleSubmit1 = () => {
    setLoadingPic1(true);
    const formData = new FormData();
    formData.append('files', file1);

    async function fetchPic1() {
      fetch('https://api.nascidosparatreinar.com/json/api/banners/1', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          //console.log(result);
          setNotify({
            type: 'success',
            message: 'Banner alterado.',
          });
          setLoadingPic1(false);
        })
        .catch((error) => {
          setNotify({
            type: 'error',
            message: 'Houve um Erro.',
          });
        });
    }
    fetchPic1();
  };

  /*-------BANNER INSTRUTOR------*/
  function handleChange2(e) {
    setErro('');
    if (e.target.files[0]) {
      if (e.target.files[0].size > 5000000) {
        setErro('A imagem deve ter no máx 5mb');
      } else {
        setFile2(e.target.files[0]);
        setPreview2(URL.createObjectURL(e.target.files[0]));
      }
    }
  }

  const handleSubmit2 = () => {
    setLoadingPic2(true);
    const formData = new FormData();
    formData.append('files', file2);

    async function fetchPic2() {
      fetch('https://api.nascidosparatreinar.com/json/api/banners/2', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          setNotify({
            type: 'success',
            message: 'Banner alterado.',
          });
          setLoadingPic2(false);
        })
        .catch((error) => {
          setNotify({
            type: 'error',
            message: 'Houve um Erro.',
          });
        });
    }
    fetchPic2();
  };

  //--FETCH USER
  React.useEffect(() => {
    async function fetchUser() {
      if (userID) {
        const theID = userID;
        const { url, options } = MANAGER_GET({ token, theID });
        const { json } = await request(url, options);
        //console.log(json);
        setVideoAluno(json.video_aluno);
        setVideoInstrutor(json.video_instrutor);
        setPreview1(json.banner_aluno);
        setPreview2(json.banner_instrutor);
      }
    }
    fetchUser();
  }, [userID]);

  return (
    <section className={styles.manageAdv}>
      <Header page="addUsuario" />
      <div className={styles.leftBar}>
        <h1 className={styles.title}>Área do Editor</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.basics}>
          <label>
            Vídeo Aluno (Código Vímeo)
            <input
              label="Vídeo Aluno"
              type="text"
              name="nome"
              value={videoAluno}
              onChange={(e) => setVideoAluno(e.target.value)}
            />
          </label>
          <label>
            Vídeo Instrutor (Código Vímeo)
            <input
              label="Vídeo Instrutor"
              type="text"
              name="nome"
              value={videoInstrutor}
              onChange={(e) => setVideoInstrutor(e.target.value)}
            />
          </label>
        </div>
        <div className={styles.btEnviar}>
          {loading ? (
            <Button disabled>Atualizando...</Button>
          ) : (
            <Button onClick={handleClick}>Atualizar Vídeos</Button>
          )}
        </div>
        <div className={styles.basics + ' ' + styles.marginTOP}>
          <label>
            Banner Aluno (630x400px)
            <div className={styles.picContent}>
              {preview1 && (
                <div
                  className={styles.preview}
                  style={{ backgroundImage: `url('${preview1}'` }}
                ></div>
              )}
              <input
                type="file"
                id="file"
                className={styles.inputFile}
                name="file"
                onChange={handleChange1}
              />
              <label htmlFor="file">
                <span>Escolha a Imagem</span>
              </label>
              {loadingPic1 ? (
                <Button disabled>Atualizando...</Button>
              ) : (
                <Button onClick={handleSubmit1}>Atualizar Banner</Button>
              )}
              <Error message={erro} />
            </div>
          </label>
          <label>
            Banner Instrutor (630x400px)
            <div className={styles.picContent}>
              {preview2 && (
                <div
                  className={styles.preview}
                  style={{ backgroundImage: `url('${preview2}'` }}
                ></div>
              )}
              <input
                type="file"
                id="file2"
                className={styles.inputFile}
                name="file2"
                onChange={handleChange2}
              />
              <label htmlFor="file2">
                <span>Escolha a Imagem</span>
              </label>
              {loadingPic2 ? (
                <Button disabled>Atualizando...</Button>
              ) : (
                <Button onClick={handleSubmit2}>Atualizar Banner</Button>
              )}
              <Error message={erro} />
            </div>
          </label>
        </div>
      </div>
    </section>
  );
};

export default ManageAdv;
