import React from 'react';
import styles from './MeuTreino.module.css';
import Input from '../../Forms/Input';
import Button from '../../Forms/Button';
import useForm from '../../Hooks/useForm';
import { UserContext } from '../../Context/UserContext';
import useFetch from '../../Hooks/useFetch';
import Error from '../../Helper/Error';
import Header from '../../Header/Header';
import { USER_PUT, USER_GET_BY_ID, USER_IMAGE_PUT } from '../../../api';
import { AppContext } from '../../Context/AppContext';
import AlunoTreino from './AlunoTreino';
import TextParq from './TextParq';
import Loading from '../../Helper/Loading';

const MeuTreino = () => {
  const token = window.localStorage.getItem('token');
  const { data, loading, error, request } = useFetch();
  const { userID, userImage } = React.useContext(UserContext);
  const { setNotify } = React.useContext(AppContext);
  const nome = useForm();
  const sobrenome = useForm();
  const email = useForm('email');
  const whatsapp = useForm('phone');
  const nascimento = useForm('date');
  const password = useForm();
  const [erro, setErro] = React.useState('');
  const [termos, setTermos] = React.useState(false);
  const [parq, setParq] = React.useState(false);
  const [quest, setQuest] = React.useState(1);
  const [alunoData, setAlunoData] = React.useState([]);
  const [firstTime, setFirstTime] = React.useState(2);
  const [preview, setPreview] = React.useState(
    'https://api.nascidosparatreinar.com/wp-content/themes/api/images/placeholder-id.png',
  );
  const [file, setFile] = React.useState();
  const [loadingPic, setLoadingPic] = React.useState(false);

  function handleClick1(e) {
    e.preventDefault();
    setQuest(2);
  }

  //---BOTÃO DE ENVIO DE EMAIL
  async function handleClick2(e) {
    e.preventDefault();
    setErro('');

    const body = {
      ...alunoData,
      nome: nome.value,
      sobrenome: sobrenome.value,
      email: email.value,
      whatsapp: whatsapp.value,
      //force_activate: 1,
      //status: 1,
      first_visit: 0,
      password: password.value,
      nascimento: nascimento.value,
    };
    //console.log(body);

    if (validaFields()) {
      try {
        const alunoID = userID;
        const { url, options } = USER_PUT({ token, body, alunoID });
        const { response, json } = await request(url, options);
        //console.log(json);
        if (response.ok) {
          setNotify({
            type: 'success',
            message: 'Usuário alterado.',
          });
          setQuest(3);
        } else {
          setNotify({
            type: 'error',
            message: 'Usuário já existe.',
          });
        }
      } catch (err) {
        setErro(err.message);
      } finally {
        setErro('');
      }
    } else {
      setErro('Preencha os campos corretamente.');
    }
  }

  const validaFields = () => {
    setErro('');
    if (
      nome.validate() &&
      sobrenome.validate() &&
      email.validate() &&
      whatsapp.validate() &&
      nascimento.validate() &&
      password.validate()
    ) {
      return true;
    }
    return false;
  };

  //---FETCH DOS DADOS
  React.useEffect(() => {
    if (userID) {
      const theID = userID;
      async function fetchDados() {
        const { url, options } = USER_GET_BY_ID({ token, theID });
        const { response, json } = await request(url, options);
        //   console.log(json);
        setAlunoData(json);
      }
      fetchDados();
    }
  }, [userID]);

  React.useEffect(() => {
    if (alunoData) {
      //  console.log(alunoData.first_visit);
      nome.initialValue(alunoData.nome);
      sobrenome.initialValue(alunoData.sobrenome);
      email.initialValue(alunoData.email);
      whatsapp.initialValue(alunoData.whatsapp);
      setFirstTime(alunoData.first_visit);
    }
  }, [alunoData]);

  const handleSubmit = () => {
    setLoadingPic(true);
    const formData = new FormData();

    formData.append('files', file);
    //console.log(formData);
    const photo = formData;

    async function fetchPic() {
      /*       const { url, options } = USER_IMAGE_PUT({ token, photo });
      const { response, json } = await request(url, options);
      console.log(json);
      console.log(response);
      if (response.ok) {
        setNotify({
          type: 'success',
          message: 'Foto alterada.',
        });
      } else {
        setNotify({
          type: 'error',
          message: 'Houve um Erro.',
        });
      }
    } */
      fetch('https://api.nascidosparatreinar.com/json/api/user_image', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          setNotify({
            type: 'success',
            message: 'Foto alterada.',
          });
          setLoadingPic(false);
          setFirstTime('0');
        })
        .catch((error) => {
          setNotify({
            type: 'error',
            message: 'Houve um Erro.',
          });
        });
    }
    fetchPic();
  };

  function handleChange(e) {
    setErro('');
    if (e.target.files[0]) {
      if (e.target.files[0].size > 5000000) {
        setErro('A imagem deve ter no máx 5mb');
      } else {
        setFile(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]));
      }
    }
  }

  if (loading) return <Loading type="default" />;
  if (data && firstTime === '1')
    return (
      <section className={styles.meuTreino}>
        <Header page="firstTime" />
        <div className={styles.leftBar}>
          <h1 className={styles.title}>Finalize seu Cadastro</h1>
        </div>
        {quest === 1 && (
          <div className={styles.content}>
            <div className={styles.basics2}>
              <TextParq />
              <div className={styles.checks}>
                <input
                  type="checkbox"
                  value={parq}
                  onChange={() => setParq(!parq)}
                />
                Aceito os termos PAR-Q
              </div>
            </div>

            <div className={styles.btEnviar}>
              {!parq ? (
                <Button disabled>Continuar</Button>
              ) : (
                <Button onClick={handleClick1}>Continuar</Button>
              )}
            </div>
          </div>
        )}
        {quest === 2 && (
          <div className={styles.content}>
            <div className={styles.basics}>
              <Input label="Nome" type="text" name="nome" {...nome} />
              <Input
                label="Sobrenome"
                type="email"
                name="sobrenome"
                {...sobrenome}
              />
              <Input label="Email" type="email" nome="email" {...email} />
              <Input label="Whatsapp" type="email" nome="email" {...whatsapp} />
              <Input
                label="Data de nascimento"
                type="text"
                nome="text"
                placeholder="DD-MM-AAAA"
                {...nascimento}
              />
              <Input
                label="Nova Senha"
                type="password"
                name="senha"
                {...password}
              />
            </div>
            <div className={styles.checks}>
              <input
                type="checkbox"
                value={termos}
                onChange={() => setTermos(!termos)}
              />
              Li e concordo com os termos contratados{' '}
              <a
                href="https://api.nascidosparatreinar.com/wp-content/uploads/2022/10/contrato_NPT.pdf"
                target="_blank"
              >
                (Ver Termos)
              </a>
            </div>

            <div className={styles.btEnviar}>
              {loading || !termos ? (
                <Button disabled>Salvar</Button>
              ) : (
                <Button onClick={handleClick2}>Salvar</Button>
              )}
              {!validaFields.value && <Error message={erro} />}
            </div>
          </div>
        )}
        {quest === 3 && (
          <div className={styles.content}>
            <div className={styles.basics2}>
              <div className={styles.picContent}>
                {preview && (
                  <div
                    className={styles.preview}
                    style={{ backgroundImage: `url('${preview}'` }}
                  ></div>
                )}
                <input
                  type="file"
                  id="file"
                  className={styles.inputFile}
                  name="file"
                  onChange={handleChange}
                />
                <label htmlFor="file">
                  <span>Escolha a foto</span>
                </label>
                {loadingPic ? (
                  <Button disabled>Salvando...</Button>
                ) : (
                  <Button onClick={handleSubmit}>CONCLUIR CADASTRO</Button>
                )}
                <Error message={erro} />
              </div>
            </div>
          </div>
        )}
      </section>
    );

  if (data && firstTime === '0') return <AlunoTreino alunoData={alunoData} />;
};

export default MeuTreino;
