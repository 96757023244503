import React from 'react';
import styles from './PickTax.module.css';
import { TERMS_GET } from '../../../api';
import useFetch from '../../Hooks/useFetch';
import Loading from '../../Helper/Loading';
import Select from 'react-select';

//style React Select
const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    border: '1px solid #eee',
    fontSize: '1rem',
    padding: '.6rem',
    borderRadius: '.4rem',
    margin: '15px 0',
    '&:hover': {
      border: '1px solid #eb6018',
    },
  }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
    };
  },
};

const PickTax = ({ slug, nome, value, setValue }) => {
  const token = window.localStorage.getItem('token');
  //const [value, setValue] = React.useState([]);
  //console.log(nome);
  const { data, loading, error, request } = useFetch();

  const [filteredArray, setFilteredArray] = React.useState(null); //array filtrado somente id, label

  React.useEffect(() => {
    async function fetchTerms() {
      const { url, options } = TERMS_GET({ slug, token });
      const { json } = await request(url, options);

      //filtra array somente com campo id e label
      setFilteredArray(
        json.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      );
    }
    fetchTerms();
  }, []);

  const handleChange = (data) => {
    setValue(data);
    //setValue((value) => [...value, data]);
  };

  if (loading) return <Loading type="dots" />;
  if (data)
    return (
      <div>
        {/*<pre>{JSON.stringify(value)}</pre>*/}
        <Select
          options={filteredArray && filteredArray}
          placeholder={nome}
          value={value}
          onChange={(...args) => handleChange(...args)}
          isSearchable={false}
          isMulti={false}
          styles={colourStyles}
        />
      </div>
    );
  else return null;
};

export default PickTax;
